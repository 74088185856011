import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

type Props = {
  onClick: (key: string) => void;
  itemKey: string;
};

const ExportButton = ({ onClick, itemKey }: Props) => {
  const [isExported, setIsExported] = useState(false);
  const theme = useTheme();

  const handleButtonClick = () => {
    setIsExported((prev) => !prev);
    onClick(itemKey);
  };

  return (
    <Button
      variant="contained"
      disableElevation
      fullWidth
      color="primary"
      sx={{
        borderRadius: "0px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        backgroundColor: isExported
          ? theme.palette.primary.main
          : theme.palette.grey[700],
        color: isExported ? "black" : "white",
      }}
      onClick={handleButtonClick}
    >
      {isExported ? "Remove from export" : "Use for export"}
    </Button>
  );
};

export default ExportButton;
