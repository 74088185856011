import {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  RefAttributes,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  SetStateAction,
} from "react";

import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Divider from "@mui/material/Divider";
import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import React from "react";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { table } from "console";
import LoadingButton from "@mui/lab/LoadingButton";

import ExportButton from "./ads/adsExportButton";

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  if (props && props.className == "pageTotal") {
    return (
      <Box
        sx={{
          p: 2,
          m: 1,
          width: "calc(33% - 16px)",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.100",
          color: (theme) =>
            theme.palette.mode === "dark" ? "white" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
          ...sx,
        }}
        {...other}
      />
    );
  } else {
    return (
      <Box
        sx={{
          p: 2,
          m: 1,
          width: "calc(33% - 16px)",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "grey.900" : "grey.100",
          color: (theme) =>
            theme.palette.mode === "dark" ? "white" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
          ...sx,
        }}
        {...other}
      />
    );
  }
}

function Capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

function formatNumber(number: number) {
  // Check if the number has decimals
  if (Number.isInteger(number)) {
    // If it's an integer, convert to string and add commas for thousands and millions separators
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    // If it's a floating-point number, round it to two decimal places and then format with commas
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function EnhancedTableHead(props: {
  order: any;
  orderBy: any;
  rowCount: any;
  onRequestSort: any;
}) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell
          align={"left"}
          padding={"normal"}
          sortDirection={false}
          width={"100%"}
          // className={headCell.className}
        >
          <>Ads Campaigns</>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: '#3b3b3b',
    //   color: 'white',
    fontWeight: "bold",
  },
  // [`&.${tableCellClasses.body}`]: {
  fontSize: 14,
  maxWidth: 200,
  width: 200,
  minWidth: 200,
  whiteSpace: "nowrap",
  [`&.expandedDetails`]: {
    paddingLeft: 56,
  },
  [`&.checkBoxleft`]: {
    fontSize: 12,
    maxWidth: 40,
    width: 40,
    minWidth: 40,
    paddingLeft: "3px",
    paddingRight: "3px",
  },

  // },
}));

type AdsDataProps = {
  tableData: {
    [key: string]: any;
  };
  pageNames: {
    [key: string]: any;
  };
  adsSearch: string;
  exportList: {
    [key: string]: any;
  };
  setExportList: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
};
const AdsTable = React.memo(function AdsData({
  tableData,
  pageNames,
  adsSearch,
  exportList,
  setExportList,
}: AdsDataProps) {
  useEffect(() => {
    // console.log('data');
  }, []);
  // console.log(tableData);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");

  if (adsSearch != "") {
    // Filter pageNames based on the adsSearch pattern
    const filteredPageNames = Object.entries(pageNames).reduce(
      (acc, [key, value]) => {
        if (
          key.toLowerCase().includes(adsSearch) ||
          value.toLowerCase().includes(adsSearch.toLowerCase())
        ) {
          acc[key] = value;
        }
        return acc;
      },
      {} as any
    );

    // create an array of the keys of the filteredPageNames object
    const pageUrlFiltered = Object.keys(filteredPageNames);

    // Filter tableData based on the adsSearch pattern
    const filteredTableData = Object.entries(tableData).reduce(
      (acc, [key, value]) => {
        // check if key is in the pageUrlFiltered array
        const keys = Object.keys(value).map((key) => key.toLowerCase());
        if (
          pageUrlFiltered.includes(key) ||
          keys.some((k) => k.includes(adsSearch.toLowerCase()))
        ) {
          acc[key] = value;
        }

        return acc;
      },
      {} as any
    );

    // console.log(filteredPageNames);
    console.log(filteredTableData);

    tableData = filteredTableData;
  }
  // count the number of rows in the table
  const count = Object.keys(tableData).length;
  // console.log(count);

  const handleRequestSort = (event: any, property: string) => {
    // console.log(property);
  };

  const theme = useTheme();

  const handleExport = (key: string) => {
    const item = tableData[key];

    // Create a new copy of the exportList
    const updatedExportList = { ...exportList };

    // check if the object exportList contains the item
    if (updatedExportList.hasOwnProperty(key)) {
      // if the item is in the exportList, remove it from the exportList
      delete updatedExportList[key];
    } else {
      // if the item is not in the exportList, add it to the exportList
      updatedExportList[key] = item;
    }

    // Update the exportList state immutably
    setExportList(updatedExportList);
  };

  // const handleAddToExport = (row: any) => {
  //   setExportList([...exportList, row]);
  // };

  // const handleRemoveFromExport = (row: any) => {
  //   setExportList(exportList.filter((item) => item.id !== row.id));
  // };

  if (count > 0) {
    return (
      <div className="adsTable">
        <TableContainer component={Paper} id="leadsTableContainer">
          <Table
            className="sort-table"
            stickyHeader
            size="small"
            aria-labelledby="adsList"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={count}
            />

            <TableBody>
              {Object.keys(tableData).map((key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <div style={{ width: "100%" }}>
                        <Box
                          mt={1}
                          mb={1}
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            width: "100%",
                          }}
                        >
                          {Object.keys(tableData[key]).map((key2) => {
                            return (
                              <Item
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "0px",
                                }}
                                key={key2}
                                className={Camelize(key2)}
                              >
                                {/* if key2 == "total_revenue" { */}
                                {key2 == "Page total" ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      flex: 1,
                                    }}
                                  >
                                    <div
                                      style={{ width: "100%", padding: "16px" }}
                                    >
                                      <a
                                        href={key}
                                        target="_blank"
                                        rel="noreferrer"
                                        color="black"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <Typography
                                          variant="h6"
                                          fontWeight="bold"
                                          lineHeight={1.3}
                                          color={"white"}
                                          mb={1}
                                          sx={{ textDecoration: "none" }}
                                        >
                                          {pageNames[key]}
                                        </Typography>

                                        <Tooltip title={key}>
                                          <Typography
                                            variant="caption"
                                            display={"block"}
                                            color={"white"}
                                            mb={2}
                                            sx={{
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {key}
                                          </Typography>
                                        </Tooltip>
                                      </a>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          width: "100%",
                                        }}
                                      >
                                        {Object.keys(tableData[key][key2]).map(
                                          (key3) => {
                                            return (
                                              <div
                                                key={key3}
                                                style={{ width: "50%" }}
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  fontWeight="bold"
                                                  mt={1}
                                                  textTransform={"uppercase"}
                                                  lineHeight={1.5}
                                                >
                                                  {key3 == "cost" ? " £" : " "}
                                                  {formatNumber(
                                                    tableData[key][key2][key3]
                                                  )}
                                                </Typography>
                                                <Typography
                                                  variant="overline"
                                                  fontWeight="bold"
                                                  mb={1}
                                                  lineHeight={1.5}
                                                >
                                                  {Capitalize(key3)}
                                                </Typography>
                                              </div>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </div>

                                    <Box mt={"auto"}>
                                      <Divider
                                        sx={{
                                          marginTop: 4,
                                          borderColor: "divider",
                                        }}
                                      />
                                      <div
                                        style={{
                                          paddingLeft: "0px",
                                          paddingRight: "0px",
                                        }}
                                      >
                                        <ExportButton
                                          onClick={handleExport}
                                          itemKey={key}
                                        />
                                      </div>
                                    </Box>
                                  </div>
                                ) : (
                                  <div
                                    style={{ width: "100%", padding: "16px" }}
                                  >
                                    <Tooltip title={"Campaign name"}>
                                      <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        lineHeight={1.3}
                                      >
                                        {key2}
                                      </Typography>
                                    </Tooltip>

                                    {Object.keys(tableData[key][key2]).map(
                                      (key3) => {
                                        // count the number of tableData[key][key2]
                                        if (
                                          (key3 == "Campaign total" &&
                                            Object.keys(tableData[key][key2])
                                              .length > 2) ||
                                          key3 != "Campaign total"
                                        ) {
                                          return (
                                            <div key={key3}>
                                              <Divider
                                                component="div"
                                                sx={{
                                                  marginTop: 1,
                                                  marginBottom: 0,
                                                }}
                                              />
                                              <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                mt={1}
                                              >
                                                {key3 != "Campaign total" ? (
                                                  <Tooltip title={"Source"}>
                                                    <Chip
                                                      label={Capitalize(key3)}
                                                      size="small"
                                                      variant="outlined"
                                                    />
                                                  </Tooltip>
                                                ) : (
                                                  <Chip
                                                    label={Capitalize(key3)}
                                                    size="small"
                                                    variant="outlined"
                                                  />
                                                )}
                                              </Typography>

                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  width: "100%",
                                                }}
                                              >
                                                {Object.keys(
                                                  tableData[key][key2][key3]
                                                ).map((key4) => {
                                                  return (
                                                    <div
                                                      key={key4}
                                                      style={{ width: "50%" }}
                                                    >
                                                      <Typography
                                                        variant="body1"
                                                        display={"block"}
                                                        lineHeight={1.5}
                                                        fontWeight="bold"
                                                        mt={0.5}
                                                      >
                                                        {key4 == "cost"
                                                          ? " £"
                                                          : " "}{" "}
                                                        {formatNumber(
                                                          tableData[key][key2][
                                                            key3
                                                          ][key4]
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        variant="overline"
                                                        fontWeight="bold"
                                                        display={"block"}
                                                        lineHeight={1.5}
                                                        mb={0.7}
                                                      >
                                                        {Capitalize(key4)}
                                                      </Typography>
                                                    </div>
                                                  );
                                                })}
                                              </Box>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                )}
                              </Item>
                            );
                          })}
                        </Box>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    return (
      <LoadingButton
        disabled
        sx={{ mt: 2, mb: 6, width: "100%" }}
        variant="outlined"
      >
        No results
      </LoadingButton>
    );
  }
});

export default AdsTable;
