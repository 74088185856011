import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { Navigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { createStyles, Theme } from "@mui/material/styles";
// import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import { Grid, Container, Typography } from "@mui/material";
import Logo from "../assets/logo.svg";

import "../App.css";

import Social from "../components/social/social";

const themeDark = createTheme({
  typography: {
    fontFamily: "Helvetica, sans-serif", // default Material-UI font
    h2: {
      fontSize: "22px",
      fontWeight: "bold",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "#454a4f",
        },
        head: {
          backgroundColor: "#000000",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          borderColor: "#454a4f",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontWeight: "bold",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          " .MuiTableSortLabel-icon": {
            color: "white",
            opacity: "0.2",
          },
          "&.Mui-active .MuiTableSortLabel-icon": {
            color: "white",
            opacity: "1",
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#26E6FF",
      contrastText: "#000000",
    },
    secondary: {
      main: "#FF7390",
    },
    //   arrows: {
    //     default: "#f5f5f5"
    //   },
    //   grey: {
    //       main: '#FF7390'
    //   },
    text: {
      primary: "#ffffff",
    },
    background: {
      default: "#121212",
    },
  },
});

function Home() {
  var userJson = localStorage.getItem("user");

  function testJSON(text: string | null) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  var loggedInUser = testJSON(userJson) ? JSON.parse(userJson || "") : null;

  // const photoUrlMaxLength = 1000;

  return (
    <ThemeProvider theme={themeDark}>
      <CssBaseline />
      <Container maxWidth="xl" className={"dark-mode"}>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 5, width: "100%" }}
        >
          <Grid item xs={3}>
            {/* <Tooltip title={mode == 'dark' ? "Switch to light mode": "Switch to dark mode"}>
                            <FormControlLabel
                                control={<MaterialUISwitch size='small' sx={{ m: 0 }} defaultChecked onChange={handleChangeMode} />}
                                label="" 
                            />
                        </Tooltip> */}
          </Grid>
          <Grid item xs={6}>
            <Link className={"dark-logo"} to={"/"}>
              <img className="logo" src={Logo} alt={"logo"} />
            </Link>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>

        <Typography sx={{ mb: 2 }} variant="h2" align="center">
          UTM Generator
        </Typography>

        <>
          {loggedInUser &&
          loggedInUser.email &&
          loggedInUser.email.includes("@raconteur.net") ? (
            // <AdsReport></AdsReport>  <GoogleAds></GoogleAds>
            <Social />
          ) : loggedInUser &&
            loggedInUser.email &&
            !loggedInUser.email.includes("@raconteur.net") ? (
            <>
              <h3>Not a raconteur account</h3>
            </>
          ) : (
            <>
              <Navigate to="/" />
            </>
          )}
        </>
      </Container>
    </ThemeProvider>
  );
}

export default Home;
