import React, { useState, useEffect, memo, useMemo, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  LinearProgress,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { time } from "console";

interface ExportSettings {
  pageViews?: boolean;
  pageViewBreakDown?: boolean;
  pageViewKpi?: number;
  clicks?: boolean;
  impressions?: boolean;
  cost?: boolean;
  ctr?: boolean;
  registered?: boolean;
  registeredBreakDown?: boolean;
  sessionDuration?: boolean;
  sessionDurationBreakDown?: boolean;
  screenShoorUrl?: string;
}

interface PCAExportList {
  clicks?: number;
  impressions?: number;
  page_views?: number;
  cost?: number;
}

interface Props {
  pcaExportList: PCAExportList;
  exportList: any; // Adjust this type according to the actual shape of your data
  dateRange: string;
  exportSettings: ExportSettings;
  imageDriveIdParent: (childData: any) => void;
  generatedPercentagesParent: (childData: any) => void;
  generatedAveragesParent: (childData: any) => void;
}

const StyledCircle = styled("div")(({ theme }) => ({
  width: "calc(33.33% - 40px)",
  marginRight: "40px",
  marginBottom: "9%",
  borderRadius: "50%",
  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 9px 0px",
  position: "relative",
  color: "white",
  [`&:nth-of-type(3n+1)`]: {
    backgroundColor: "#fd2341",
  },
  [`&:nth-of-type(3n+2)`]: {
    backgroundColor: "#24e2ff",
    color: "black",
    [`.breakDownCircle .circleText`]: {
      color: "white",
    },
  },
  [`&:nth-of-type(3n+3)`]: {
    backgroundColor: "#ffc751",
    color: "black",
    [`.breakDownCircle .circleText`]: {
      color: "white",
    },
  },
}));

const circleStyle = {} as React.CSSProperties;

const circleTextStyle = {
  position: "absolute",
  width: "100%",
  paddingRight: 3,
  paddingLeft: 3,
  // color: "white",
  display: "block",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "12px",
  textAlign: "center",
} as React.CSSProperties;

const breakDownCircleStyle = {
  width: "80%",
  right: "-50px",
  top: "100%",
  borderRadius: "50%",
  overflow: "hidden",
  position: "absolute",
  transform: "translateY(-50%)",
  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 9px 0px",
  // styles for 1st, 4th, 7th elements, etc.
  backgroundColor: "#00519b",
} as React.CSSProperties;

function fromUrlToFileName(url: string) {
  let fileName = url.split("/").pop() || "";
  fileName.replace(/[^a-zA-Z0-9]/g, "");
  // remmove everything after # or ?
  fileName = fileName.split("#")[0];
  fileName = fileName.split("?")[0];
  return fileName;
}

const checkFileExistence = async (fileName: string) => {
  try {
    const response = await fetch("/.netlify/functions/adsScreenshotCheck", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fileName: fromUrlToFileName(fileName),
      }),
    });
    const data = await response.json();
    // console.log("data", data);
    if (data.fileId) {
      const screenshotData = {
        fileId: data.fileId,
        base64Image: data.base64Image,
      };
      return screenshotData; // Return fileId if the file exists
    } else {
      return false; // Return false if the file is still uploading
    }
  } catch (error) {
    console.error("Error checking file existence:", error);
    return false;
  }
};
const checkFilePeriodically = async (fileName: string) => {
  let attempts = 0; // Initialize attempts counter

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      attempts++; // Increment attempts counter

      const result = await checkFileExistence(fileName);

      // console.log("result", result);

      if (result !== false) {
        clearInterval(interval); // Stop further checks once result is not false
        // console.log(`File ${fileName} found with fileId: ${result}`);
        resolve(result); // Resolve the promise with fileId
      } else {
        // console.log(`File ${fileName} not found yet.`);
      }

      if (attempts >= 7) {
        clearInterval(interval); // Stop further checks after 5 attempts
        console.log(
          `Reached maximum attempts (${attempts}). Stopping further checks.`
        );
        resolve(false); // Resolve with false if max attempts reached
      }
    }, 10000); // Check every 10 seconds (10000 milliseconds)
  });
};
const fetchScreenShot = async (pageUrl: string) => {
  try {
    // console.log(fromUrlToFileName(pageUrl));
    const response = await fetch(
      "/.netlify/functions/adsScreenshot-background",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pageUrl: pageUrl,
          fileName: fromUrlToFileName(pageUrl),
        }),
      }
    )
      .then((res) => res.json())
      .catch((error) => "timeout");

    // return response;
    // console.log("adssScreenshot BACKGROUND", response);

    // Start checking for the file existence
    const fileId = await checkFilePeriodically(fromUrlToFileName(pageUrl));
    // console.log("fileId", fileId);
    return fileId; // Return the fileId or false if not found
  } catch (error) {
    console.log(error);
    return false;
  }
};

const ChartPreview = memo(function ChartPreview(props: Props) {
  const finalPianoCall = useMemo(() => ({} as Record<string, string[]>), []);

  const {
    imageDriveIdParent,
    generatedPercentagesParent,
    generatedAveragesParent,
  } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [averages, setAverages] = useState<any>({});
  const [base64Image, setBase64Image] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [generatedPercentages, setGeneratedPercentages] = useState({
    timePercentage: 0,
    ctr: 0,
    articleViews: 0,
  });
  const [error, setError] = useState("");
  const averageIndustry = "1:03";
  const averageIndustrySeconds = useMemo(
    () =>
      averageIndustry
        .split(":")
        .reduce((acc: number, cur: string) => acc * 60 + Number(cur), 0),
    [averageIndustry]
  );

  const fetchLastPianoCall = useCallback(
    async (dataRange: string, finalPianoCall: any) => {
      try {
        const response = await axios.post("/.netlify/functions/adsPianoFinal", {
          dataRange,
          finalPianoCall,
        });
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    generatedAveragesParent(averages);
  }, [averages]);

  useEffect(() => {
    callMe();
  }, [props.pcaExportList, props.exportList, props.exportSettings.pageViewKpi]);

  const callMe = useCallback(() => {
    if (
      Object.keys(props.pcaExportList).length > 0 &&
      props.pcaExportList.page_views &&
      props.pcaExportList.page_views > 0 &&
      props.pcaExportList.clicks &&
      props.pcaExportList.impressions
    ) {
      const finalPianoCall: Record<string, string[]> = {};
      // console.log("run 1");
      Object.keys(props.exportList).forEach((articleUrl) => {
        Object.keys(props.exportList[articleUrl]).forEach((campaignName) => {
          if (campaignName !== "Page total") {
            Object.keys(props.exportList[articleUrl][campaignName]).forEach(
              (utmSource) => {
                if (
                  utmSource !== "Campaign total" &&
                  props.exportList[articleUrl][campaignName][utmSource].checked
                ) {
                  if (!finalPianoCall[articleUrl]) {
                    finalPianoCall[articleUrl] = [];
                  }
                  finalPianoCall[articleUrl].push(utmSource);
                }
              }
            );
          }
        });
      });
      // setFinalPianoCall(finalPianoCall);
      fetchLastPianoCall(props.dateRange, finalPianoCall).then((response) => {
        setLoading(false);
        setAverages(response.returnResult);
        const averageCampaign = response.returnResult.averageTime;
        const averageCampaignSeconds = averageCampaign
          .split(":")
          .reduce((acc: number, cur: string) => acc * 60 + Number(cur), 0);
        const differenceFromIndustry =
          averageCampaignSeconds - averageIndustrySeconds;
        const percentageDifference = parseFloat(
          ((differenceFromIndustry / averageIndustrySeconds) * 100).toFixed(0)
        );
        const ctr = (
          ((props.pcaExportList.clicks || 0) /
            (props.pcaExportList.impressions || 0)) *
          100
        ).toFixed(2);
        const KPIpageView = props.exportSettings.pageViewKpi || 4000;
        const CampaignPageView = props.pcaExportList.page_views;
        const differenceFromKpi = (CampaignPageView || 0) - KPIpageView;
        const KPIpageViewPercentage = parseFloat(
          ((differenceFromKpi / KPIpageView) * 100).toFixed(2)
        );
        setGeneratedPercentages({
          timePercentage: percentageDifference,
          ctr: parseFloat(ctr),
          articleViews: KPIpageViewPercentage,
        });
        generatedPercentagesParent({
          timePercentage: percentageDifference,
          ctr: parseFloat(ctr),
          articleViews: KPIpageViewPercentage,
        });
      });
    }
  }, [props.exportSettings.pageViewKpi]);

  // useEffect(() => {
  //   // console.log("memo 2");
  //   if (
  //     props.exportSettings.screenShoorUrl &&
  //     props.exportSettings.screenShoorUrl !== ""
  //   ) {
  //     setImageLoading(true);
  //     console.log("run 2");
  //     fetchScreenShot(props.exportSettings.screenShoorUrl).then((response) => {
  //       if (response) {
  //         setBase64Image(response.screenshotBase64);
  //         setImageLoading(false);
  //         base64ImageParent({ base64Image: response.screenshotBase64 });
  //       } else {
  //         setError("Error fetching screenshot, please try again");
  //         base64ImageParent({ base64Image: "none" });
  //         setImageLoading(false);
  //       }
  //     });
  //   }
  // }, [props.exportSettings.screenShoorUrl]);

  const screenShotStuff = useMemo(() => {
    if (
      props.exportSettings.screenShoorUrl &&
      props.exportSettings.screenShoorUrl !== ""
    ) {
      setImageLoading(true);
      fetchScreenShot(props.exportSettings.screenShoorUrl).then(
        (response: any) => {
          if (response) {
            setBase64Image(response.base64Image);
            // console.log(response);
            setImageLoading(false);
            imageDriveIdParent({ imageDriveId: response.fileId });
            // base64ImageParent({ base64Image: response.base64Image });
          } else {
            setError("Error fetching screenshot, please try again");
            // base64ImageParent({ base64Image: "none" });
            setImageLoading(false);
          }
        }
      );
    }
  }, [props.exportSettings.screenShoorUrl]);

  if (Object.keys(props.pcaExportList).length === 0) {
    return <div>No data to display</div>;
  }

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "white",
            color: "black",
            position: "relative",
            width: "100%",
            paddingTop: "56.25%", // 16:9 aspect ratio (modify as needed)
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(https://res.cloudinary.com/yumyoshojin/image/upload/v1716890045/rac/media/preview-pca.jpg)`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "38%",
                top: "39%",
                width: "56%",
                // overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              {props.exportSettings.pageViews && (
                <StyledCircle className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {formatNumber(props.pcaExportList.page_views || 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "90%",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      article views
                    </Typography>
                  </div>

                  {props.exportSettings.pageViews &&
                    props.exportSettings.pageViewBreakDown && (
                      <div
                        style={breakDownCircleStyle}
                        className="breakDownCircle"
                      >
                        <div
                          style={{
                            paddingBottom: "100%",
                            width: "100%",
                            display: "block",
                          }}
                        ></div>
                        <div style={circleTextStyle} className="circleText">
                          <Typography
                            variant="body2"
                            lineHeight={1}
                            sx={{ fontWeight: "bold", fontSize: "100%" }}
                          >
                            {formatNumberWithNoDecimals(
                              generatedPercentages.articleViews
                            )}
                            %
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "80%",
                              // whiteSpace: "nowrap",
                              display: "block",
                              mt: 0.4,
                              lineHeight: 1.3,
                              fontWeight: "normal",
                            }}
                          >
                            {props.pcaExportList.page_views ||
                            0 - (props.exportSettings.pageViewKpi || 0) >= 0
                              ? "above"
                              : "below"}{" "}
                            KPI of{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {formatNumber(
                                Number(props.exportSettings.pageViewKpi)
                              )}
                            </span>{" "}
                            views
                          </Typography>
                        </div>
                      </div>
                    )}
                </StyledCircle>
              )}

              {props.exportSettings.sessionDuration && (
                <StyledCircle style={circleStyle} className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {fromNumberTimeToString(averages.averageTime)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "90%",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      avg. session
                    </Typography>
                  </div>

                  {props.exportSettings.sessionDuration &&
                    props.exportSettings.sessionDurationBreakDown && (
                      <div
                        style={breakDownCircleStyle}
                        className="breakDownCircle"
                      >
                        <div
                          style={{
                            paddingBottom: "100%",
                            width: "100%",
                            display: "block",
                          }}
                        ></div>
                        <div style={circleTextStyle} className="circleText">
                          <Typography
                            variant="body2"
                            lineHeight={1}
                            sx={{ fontWeight: "bold", fontSize: "100%" }}
                          >
                            {generatedPercentages.timePercentage}%
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "80%",
                              // whiteSpace: "nowrap",
                              display: "block",
                              mt: 0.4,
                              lineHeight: 1.3,
                              fontWeight: "normal",
                            }}
                          >
                            {generatedPercentages.timePercentage &&
                            generatedPercentages.timePercentage >= 0
                              ? "above"
                              : "below"}{" "}
                            industry avg. of{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {fromNumberTimeToString(averageIndustry)}
                            </span>
                          </Typography>
                        </div>
                      </div>
                    )}
                </StyledCircle>
              )}

              {props.exportSettings.registered && (
                <StyledCircle style={circleStyle} className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {Number(
                        averages.userSeniority.highLevelPercentage
                      ).toFixed(0)}
                      %
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "80%",
                        // whiteSpace: "nowrap",
                        display: "block",
                        mt: 0.4,
                        lineHeight: 1.2,
                        pl: 1,
                        pr: 1,
                        fontWeight: "bold",
                      }}
                    >
                      of registered readers were ‘Head of’ <br />
                      or above
                    </Typography>
                  </div>
                  {props.exportSettings.registered &&
                    props.exportSettings.registeredBreakDown && (
                      <div
                        style={breakDownCircleStyle}
                        className="breakDownCircle"
                      >
                        <div
                          style={{
                            paddingBottom: "100%",
                            width: "100%",
                            display: "block",
                          }}
                        ></div>
                        <div style={circleTextStyle} className="circleText">
                          {averages.userSeniority.CXO !== "0.00" && (
                            <Typography
                              variant="body2"
                              sx={{
                                display: "block",
                                fontSize: "80%",
                                lineHeight: 1.2,
                              }}
                            >
                              CXO:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {Number(averages.userSeniority.CXO).toFixed(0)}%
                              </span>
                            </Typography>
                          )}
                          {averages.userSeniority.director !== "0.00" && (
                            <Typography
                              variant="body2"
                              sx={{
                                display: "block",
                                fontSize: "80%",
                                lineHeight: 1.2,
                              }}
                            >
                              Director:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {Number(
                                  averages.userSeniority.director
                                ).toFixed(0)}
                                %
                              </span>
                            </Typography>
                          )}

                          {averages.userSeniority.headOfDepartment !==
                            "0.00" && (
                            <Typography
                              variant="body2"
                              sx={{
                                display: "block",
                                fontSize: "80%",
                                lineHeight: 1.2,
                              }}
                            >
                              Head of:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {Number(
                                  averages.userSeniority.headOfDepartment
                                ).toFixed(0)}
                                %
                              </span>
                            </Typography>
                          )}
                          {averages.userSeniority.manager !== "0.00" && (
                            <Typography
                              variant="body2"
                              sx={{
                                display: "block",
                                fontSize: "80%",
                                lineHeight: 1.2,
                              }}
                            >
                              Manager:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {Number(averages.userSeniority.manager).toFixed(
                                  0
                                )}
                                %
                              </span>
                            </Typography>
                          )}
                        </div>
                      </div>
                    )}
                </StyledCircle>
              )}

              {props.exportSettings.impressions && (
                <StyledCircle style={circleStyle} className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {formatNumber(props.pcaExportList.impressions || 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "90%",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      total impressions
                    </Typography>
                  </div>
                </StyledCircle>
              )}

              {props.exportSettings.clicks && (
                <StyledCircle style={circleStyle} className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {formatNumber(props.pcaExportList.clicks || 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "90%",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      total clicks
                    </Typography>
                  </div>
                </StyledCircle>
              )}

              {props.exportSettings.cost && (
                <StyledCircle style={circleStyle} className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {formatNumber(props.pcaExportList.cost || 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "90%",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      total cost
                    </Typography>
                  </div>
                </StyledCircle>
              )}

              {props.exportSettings.ctr && (
                <StyledCircle style={circleStyle} className="circlePreview">
                  <div
                    style={{
                      paddingBottom: "100%",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <div style={circleTextStyle} className="circleText">
                    <Typography
                      variant="body1"
                      lineHeight={1}
                      sx={{ fontWeight: "bold", fontSize: "175%" }}
                    >
                      {generatedPercentages.ctr}%
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "90%",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                    >
                      average CTR
                    </Typography>
                  </div>
                </StyledCircle>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                left: "3.8%",
                top: "41.7%",
                width: "30%",
                height: "100%",
                borderRadius: "1.5%",
                overflow: "hidden",
              }}
            >
              {imageLoading ? (
                <div
                  style={{
                    // position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1.5%",
                    backgroundColor: theme.palette.grey[900],
                  }}
                >
                  <LinearProgress />
                </div>
              ) : (
                <>
                  {base64Image && base64Image != "" ? (
                    <img
                      src={"data:image/jpeg;base64," + base64Image}
                      style={{ width: "100%", height: "auto" }}
                    />
                  ) : (
                    // <img
                    //   src={"data:image/jpeg;base64," + base64Image}
                    //   style={{ width: "100%", height: "auto" }}
                    // />
                    <div
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        width: "100%",
                        height: "100%",
                        borderRadius: "1.5%",
                        backgroundColor: theme.palette.grey[900],
                      }}
                    >
                      <Alert
                        severity="info"
                        icon={false}
                        variant="filled"
                        sx={{
                          width: "100%",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        The screenshot is taking longer than expected. <br />
                        You can try again or export the slide without
                        screenshot.
                      </Alert>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
      )}
      {error && error != "" && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          message={error}
          onClose={() => setError("")}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            variant="filled"
            sx={{ width: "100%", color: "white", fontWeight: "bold" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
});

function formatNumber(number: number) {
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function formatNumberWithNoDecimals(number: number) {
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function fromNumberTimeToString(string: string) {
  const [minutes, seconds] = string.toString().split(":");
  return `${minutes}m ${seconds}s`;
}

export default ChartPreview;
