import React, { useState, useEffect, useMemo } from "react";

import AdsReport from "../ads";
import AdsFilter from "../adsFilter";
import axios from "axios";
import { table } from "console";
import Box from "@mui/material/Box";
import AdsTable from "../adsTable";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import ExportFunction from "./adsExportFunction";
function disableScroll() {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}

async function fetchData(url: string, data: object) {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function fetchGoogleAds(dataRange: string) {
  const url = "/.netlify/functions/adsGoogle";
  const user = localStorage.getItem("user") || "";
  const userJson = JSON.parse(user || "");
  // console.log(userJson);
  const refreshTokenPre = userJson.refresh_token;

  console.log(refreshTokenPre);

  try {
    const profileInfo = await axios.post("/.netlify/functions/googletokens", {
      refreshTokenPre,
    });
    const encodedAccessToken = profileInfo.data.encodedAccessToken;
    const accessToken = decodeURIComponent(encodedAccessToken);

    const data = {
      dataRange,
      accessToken,
    };
    return await fetchData(url, data);
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function fetchAllData(dataRange: string) {
  const [
    pianoResults,
    googleAdsResults,
    facebookAdsResults,
    linkedinAdsResults,
  ] = await Promise.all([
    fetchData("/.netlify/functions/adsPiano", { dataRange }),
    fetchGoogleAds(dataRange),
    fetchData("/.netlify/functions/adsFacebook", { dataRange }),
    fetchData("/.netlify/functions/adsLinkedin", { dataRange }),
  ]);

  // console.log(googleAdsResults);

  return {
    pianoResults,
    googleAdsResults,
    facebookAdsResults,
    linkedinAdsResults,
  };
}

function processData(results: any, adsData: string) {
  const tableDataTemp: any = {};
  const pageNames: any = {};

  results.pianoResults.pianoResults.forEach((element: any) => {
    const eventUrl = element.event_url;
    // get the url without # and ?
    const url = eventUrl.split("#")[0].split("?")[0];

    const pageName = element.page;
    // if the url is not a key of pageNames, pageName doesn't contain http and ends with - Raconteur, and is not in pageNames, add it
    if (
      !Object.keys(pageNames).includes(url) &&
      !pageName.includes("http") &&
      !Object.values(pageNames).includes(pageName)
    ) {
      // if pageName doesn't end with - Raconteur, add it
      if (!pageName.endsWith(" - Raconteur")) {
        pageNames[url] = pageName;
      } else {
        pageNames[url] = pageName.replace(" - Raconteur", "");
      }
    }

    // get the utm_campaign from element
    const utmCampaign = element.utm_campaign
      .replace(/\s/g, "")
      .replace(/\+/g, "");
    // get the utm_source from element
    const utmSource =
      element.utm_source != "adwords" ? element.utm_source : "google";

    // get the utm_medium from element
    const pageViewsLabel = "Page views";
    // get page loads from element
    const pageLoads = Number(element.m_page_loads);

    // check if the url exists in tableDataTemp
    if (tableDataTemp[url]) {
      // check if the utmCampaign exists in tableDataTemp[url]
      if (tableDataTemp[url][utmCampaign]) {
        // check if the utmSource exists in tableDataTemp[url][utmCampaign]
        if (tableDataTemp[url][utmCampaign][utmSource]) {
          // check if the utmMedium exists in tableDataTemp[url][utmCampaign][utmSource]
          if (tableDataTemp[url][utmCampaign][utmSource][pageViewsLabel]) {
            // add pageLoads to tableDataTemp[url][utmCampaign][utmSource][utmMedium]
            tableDataTemp[url][utmCampaign][utmSource][pageViewsLabel] +=
              pageLoads;
          } else {
            // add utmMedium to tableDataTemp[url][utmCampaign][utmSource]
            tableDataTemp[url][utmCampaign][utmSource][pageViewsLabel] =
              pageLoads;
          }
        } else {
          // add utmSource to tableDataTemp[url][utmCampaign]
          tableDataTemp[url][utmCampaign][utmSource] = {
            [pageViewsLabel]: pageLoads,
          };
        }
      } else {
        // add utmCampaign to tableDataTemp[url]
        tableDataTemp[url][utmCampaign] = {
          [utmSource]: { [pageViewsLabel]: pageLoads },
        };
      }
    } else {
      // add url to tableDataTemp
      tableDataTemp[url] = {
        [utmCampaign]: { [utmSource]: { [pageViewsLabel]: pageLoads } },
      };
    }
  });

  const googleProcessed: {
    [key: string]: {
      [key: string]: {
        impressions: number;
        clicks: number;
        cost: number;
      };
    };
  } = {};

  // foreach element of googleAdsResults

  let i = 0;
  results.googleAdsResults.googleAdsResults.forEach((element: any) => {
    // skip first element
    if (i == 0) {
      i++;
      return;
    }

    // check if element[0] is between the start and end date
    const startDate = new Date(adsData.split(":")[0]);
    const endDate = new Date(adsData.split(":")[1]);
    const currentDate = new Date(element[0]);

    if (currentDate < startDate || currentDate > endDate) {
      return;
    }

    const urlFull = element[1];
    const impressions = element[3];
    const clicks = element[4];
    const cost = Math.round(element[6]);

    // get the url without # and ?
    const url = urlFull.split("#")[0].split("?")[0];
    // get the utm_campaign from urlFull
    const utmCampaign =
      new URLSearchParams(urlFull)
        .get("utm_campaign")
        ?.replace(/\s/g, "")
        .replace(/\+/g, "") || "";

    // check if the url exists in googleProcessed
    if (googleProcessed[url]) {
      // check if the utmCampaign exists in googleProcessed[url]
      if (googleProcessed[url][utmCampaign]) {
        // add impressions, clicks and cost to googleProcessed[url][utmCampaign]
        googleProcessed[url][utmCampaign].impressions =
          Number(impressions) +
          Number(googleProcessed[url][utmCampaign].impressions);
        googleProcessed[url][utmCampaign].clicks =
          Number(clicks) + Number(googleProcessed[url][utmCampaign].clicks);
        googleProcessed[url][utmCampaign].cost =
          Number(cost) + Number(googleProcessed[url][utmCampaign].cost);
      } else {
        // add utmCampaign to googleProcessed[url]
        googleProcessed[url][utmCampaign] = {
          impressions: Number(impressions),
          clicks: Number(clicks),
          cost: Number(cost),
        };
      }
    } else {
      // add url to googleProcessed
      googleProcessed[url] = {
        [utmCampaign]: {
          impressions: Number(impressions),
          clicks: Number(clicks),
          cost: Number(cost),
        },
      };
    }
  });

  const twitterProcessed: {
    [key: string]: {
      [key: string]: {
        impressions: number;
        clicks: number;
        cost: number;
      };
    };
  } = {};

  let j = 0;

  const twitterCampaignsNames = results.googleAdsResults.twitterAdsResults[0];

  // console.log(results[1].twitterAdsResults[1]);

  // check if every campaign name inside twitterCampaignsNames exists inside all URLs in tableDataTemp
  results.googleAdsResults.twitterAdsResults[1].forEach((element: any) => {
    // skip first element
    // if (i == 0) {
    //   i++;
    //   return;
    // }

    // check if element[0] is between the start and end date
    const startDate = new Date(adsData.split(":")[0]);
    const endDate = new Date(adsData.split(":")[1]);
    const currentDate = new Date(element[0]);

    // console.log(currentDate);

    if (currentDate < startDate || currentDate > endDate) {
      return;
    }

    // console.log(element[0]);

    // foreach element of twitterAdsResults
    element.forEach((elementDetails: any, index: number) => {
      const campaignName = twitterCampaignsNames[index]
        .replace(/\s/g, "")
        .replace(/\+/g, "");

      let impressions = 0;
      let clicks = 0;
      let cost = 0;

      // check if elementDetails is not empty
      if (elementDetails != "") {
        // split elementDetails by comma
        const elementDetailsSplit = elementDetails.split(", ");
        // get impressions from elementDetailsSplit
        impressions = elementDetailsSplit[1];
        // get clicks from elementDetailsSplit
        clicks = elementDetailsSplit[0];
        // get cost from elementDetailsSplit
        cost = Math.round(elementDetailsSplit[2] / 1000000);
      }

      // check if the campaign name exist inside tableDataTemp
      Object.keys(tableDataTemp).forEach((url) => {
        let urlUpdate = url;

        Object.keys(tableDataTemp[url]).forEach((utmCampaign) => {
          if (utmCampaign == campaignName) {
            // get the url without # and ?

            // get the url without # and ?
            const url = urlUpdate;
            // get the utm_campaign from urlFull
            const utmCampaign = campaignName;

            // check if the url exists in twitterProcessed
            if (twitterProcessed[url]) {
              // check if the utmCampaign exists in twitterProcessed[url]
              if (twitterProcessed[url][utmCampaign]) {
                // add impressions, clicks and cost to twitterProcessed[url][utmCampaign]
                twitterProcessed[url][utmCampaign].impressions =
                  Number(impressions) +
                  Number(twitterProcessed[url][utmCampaign].impressions);
                twitterProcessed[url][utmCampaign].clicks =
                  Number(clicks) +
                  Number(twitterProcessed[url][utmCampaign].clicks);
                twitterProcessed[url][utmCampaign].cost =
                  Number(cost) +
                  Number(twitterProcessed[url][utmCampaign].cost);
              } else {
                // add utmCampaign to twitterProcessed[url]
                twitterProcessed[url][utmCampaign] = {
                  impressions: Number(impressions),
                  clicks: Number(clicks),
                  cost: Number(cost),
                };
              }
            } else {
              // add url to twitterProcessed
              twitterProcessed[url] = {
                [utmCampaign]: {
                  impressions: Number(impressions),
                  clicks: Number(clicks),
                  cost: Number(cost),
                },
              };
            }
          }
        });
      });
    });
  });

  // check if the urls in twitterProcessed exist in tableDataTemp
  Object.keys(twitterProcessed).forEach((url) => {
    const utmCampaign = Object.keys(twitterProcessed[url])[0]
      .replace(/\s/g, "")
      .replace(/\+/g, "");
    if (tableDataTemp[url]) {
      if (tableDataTemp[url][utmCampaign]) {
        if (tableDataTemp[url][utmCampaign]["twitter"]) {
          tableDataTemp[url][utmCampaign]["twitter"].clicks =
            Number(twitterProcessed[url][utmCampaign].clicks) +
            (Number(tableDataTemp[url][utmCampaign]["twitter"].clicks) || 0);
          tableDataTemp[url][utmCampaign]["twitter"].impressions =
            Number(twitterProcessed[url][utmCampaign].impressions) +
            (Number(tableDataTemp[url][utmCampaign]["twitter"].impressions) ||
              0);

          tableDataTemp[url][utmCampaign]["twitter"].cost =
            Number(twitterProcessed[url][utmCampaign].cost) +
            (Number(tableDataTemp[url][utmCampaign]["twitter"].cost) || 0);
        } else {
          tableDataTemp[url][utmCampaign]["twitter"] = {
            clicks: Number(twitterProcessed[url][utmCampaign].clicks),
            impressions: Number(twitterProcessed[url][utmCampaign].impressions),
            cost: Number(twitterProcessed[url][utmCampaign].cost),
          };
        }
      } else {
        tableDataTemp[url][utmCampaign] = {
          ["twitter"]: {
            clicks: Number(twitterProcessed[url][utmCampaign].clicks),
            impressions: Number(twitterProcessed[url][utmCampaign].impressions),

            cost: Number(twitterProcessed[url][utmCampaign].cost),
          },
        };
      }
    } else {
      tableDataTemp[url] = {
        [utmCampaign]: {
          clicks: Number(twitterProcessed[url][utmCampaign].clicks),
          ["twitter"]: {
            impressions: Number(twitterProcessed[url][utmCampaign].impressions),

            cost: Number(twitterProcessed[url][utmCampaign].cost),
          },
        },
      };
    }
  });

  // check if the urls in googleProcessed exist in tableDataTemp
  Object.keys(googleProcessed).forEach((url) => {
    const utmCampaign = Object.keys(googleProcessed[url])[0]
      .replace(/\s/g, "")
      .replace(/\+/g, "");
    if (tableDataTemp[url]) {
      if (tableDataTemp[url][utmCampaign]) {
        if (tableDataTemp[url][utmCampaign]["google"]) {
          tableDataTemp[url][utmCampaign]["google"].clicks =
            Number(googleProcessed[url][utmCampaign].clicks) +
            (Number(tableDataTemp[url][utmCampaign]["google"].clicks) || 0);
          tableDataTemp[url][utmCampaign]["google"].impressions =
            Number(googleProcessed[url][utmCampaign].impressions) +
            (Number(tableDataTemp[url][utmCampaign]["google"].impressions) ||
              0);

          tableDataTemp[url][utmCampaign]["google"].cost =
            Number(googleProcessed[url][utmCampaign].cost) +
            (Number(tableDataTemp[url][utmCampaign]["google"].cost) || 0);
        } else {
          tableDataTemp[url][utmCampaign]["google"] = {
            clicks: Number(googleProcessed[url][utmCampaign].clicks),
            impressions: Number(googleProcessed[url][utmCampaign].impressions),

            cost: Number(googleProcessed[url][utmCampaign].cost),
          };
        }
      } else {
        tableDataTemp[url][utmCampaign] = {
          ["google"]: {
            clicks: Number(googleProcessed[url][utmCampaign].clicks),
            impressions: Number(googleProcessed[url][utmCampaign].impressions),

            cost: Number(googleProcessed[url][utmCampaign].cost),
          },
        };
      }
    } else {
      tableDataTemp[url] = {
        [utmCampaign]: {
          ["google"]: {
            clicks: Number(googleProcessed[url][utmCampaign].clicks),
            impressions: Number(googleProcessed[url][utmCampaign].impressions),

            cost: Number(googleProcessed[url][utmCampaign].cost),
          },
        },
      };
    }
  });

  // check if every campaign name inside results[3].facebookAdsResults exists inside all URLs in tableDataTemp
  Object.keys(tableDataTemp).forEach((url) => {
    Object.keys(tableDataTemp[url]).forEach((utmCampaign) => {
      // remove spaces and + from campaign name
      utmCampaign = utmCampaign.replace(/\s/g, "").replace(/\+/g, "");
      if (
        Object.keys(results.facebookAdsResults.facebookAdsResults).includes(
          utmCampaign
        )
      ) {
        // if exists in results[3].facebookAdsResults adds the data inside 'facebook' keeping the old data inside 'google'
        tableDataTemp[url][utmCampaign].facebook = {
          ...tableDataTemp[url][utmCampaign].facebook,
          clicks: Number(
            results.facebookAdsResults.facebookAdsResults[utmCampaign].clicks
          ),
          impressions: Number(
            results.facebookAdsResults.facebookAdsResults[utmCampaign]
              .impressions
          ),

          cost: Number(
            results.facebookAdsResults.facebookAdsResults[utmCampaign].cost
          ),
        };
      } else {
        // console.log("I don't exist " + utmCampaign);
      }
    });
  });

  // check if every campaign name inside results[4].linkedinAdsResults exists inside all URLs in tableDataTemp
  Object.keys(tableDataTemp).forEach((url) => {
    Object.keys(tableDataTemp[url]).forEach((utmCampaign) => {
      // remove spaces and + from campaign name
      utmCampaign = utmCampaign.replace(/\s/g, "").replace(/\+/g, "");
      if (
        Object.keys(results.linkedinAdsResults.linkedinAdsResults).includes(
          utmCampaign
        )
      ) {
        // console.log("I exist " + utmCampaign);
        // console.log(results[4].linkedinAdsResults[utmCampaign]);
        // if exists in results[4].linkedinAdsResults adds the data inside 'linkedin' keeping the old data inside 'google'
        tableDataTemp[url][utmCampaign].linkedin = {
          ...tableDataTemp[url][utmCampaign].linkedin,

          clicks: Number(
            results.linkedinAdsResults.linkedinAdsResults[utmCampaign].clicks
          ),
          impressions: Number(
            results.linkedinAdsResults.linkedinAdsResults[utmCampaign]
              .impressions
          ),
          cost: Number(
            results.linkedinAdsResults.linkedinAdsResults[utmCampaign].cost
          ),
        };
      } else {
        // console.log("I don't exist " + utmCampaign);
      }
    });
  });

  // foreach campaign name inside tableDataTemp sum the values of impressions, clicks and cost inside and add them to the total
  Object.keys(tableDataTemp).forEach((url) => {
    Object.keys(tableDataTemp[url]).forEach((utmCampaign) => {
      let totalImpressions = 0;
      let totalClicks = 0;
      let totalCost = 0;
      let pageViews = 0;
      let addToTotal = true;

      // console.log(tableDataTemp[url][utmCampaign]);

      Object.keys(tableDataTemp[url][utmCampaign]).forEach((utmSource) => {
        if (tableDataTemp[url][utmCampaign][utmSource].cost) {
          // addToTotal = true;
          pageViews +=
            tableDataTemp[url][utmCampaign][utmSource]["Page views"] || 0;
          totalImpressions +=
            tableDataTemp[url][utmCampaign][utmSource].impressions || 0;
          totalClicks += tableDataTemp[url][utmCampaign][utmSource].clicks || 0;
          totalCost += tableDataTemp[url][utmCampaign][utmSource].cost || 0;
        } else {
          delete tableDataTemp[url][utmCampaign][utmSource];
          // check if the campaign as any other campaign
          if (Object.keys(tableDataTemp[url][utmCampaign]).length == 0) {
            delete tableDataTemp[url][utmCampaign];
            addToTotal = false;
          }
        }
      });

      if (addToTotal) {
        tableDataTemp[url][utmCampaign]["Campaign total"] = {
          "Page views": pageViews,
          clicks: totalClicks,
          impressions: totalImpressions,
          cost: totalCost,
        };
      } else {
        // delete the campaign from the tableDataTemp
        // console.log("delete campaign", tableDataTemp[url][utmCampaign]);
        // delete tableDataTemp[url][utmCampaign];
      }
    });
  });

  // add each Campaign total inside tableDataTemp[url]["Page total"]
  Object.keys(tableDataTemp).forEach((url) => {
    let totalImpressions = 0;
    let totalClicks = 0;
    let totalCost = 0;
    let pageViews = 0;

    Object.keys(tableDataTemp[url]).forEach((utmCampaign) => {
      pageViews +=
        tableDataTemp[url][utmCampaign]["Campaign total"]["Page views"];
      totalImpressions +=
        tableDataTemp[url][utmCampaign]["Campaign total"].impressions;
      totalClicks += tableDataTemp[url][utmCampaign]["Campaign total"].clicks;
      totalCost += tableDataTemp[url][utmCampaign]["Campaign total"].cost;
    });

    tableDataTemp[url]["Page total"] = {
      "Page views": pageViews,
      clicks: totalClicks,
      impressions: totalImpressions,
      cost: totalCost,
    };
  });

  // move Page total at the beginning of the object
  Object.keys(tableDataTemp).forEach((url) => {
    const pageTotal = tableDataTemp[url]["Page total"];
    delete tableDataTemp[url]["Page total"];
    tableDataTemp[url] = {
      "Page total": pageTotal,
      ...tableDataTemp[url],
    };
    // if pageTotal.cost == 0 remove the tableDataTemp[url]
    if (pageTotal.cost == 0) {
      delete tableDataTemp[url];
    }
    // if url contains youtube, remove it
    if (
      url.includes("youtube.com") ||
      url.includes("doubleclick.net") ||
      url.includes("googleadservices.com")
    ) {
      delete tableDataTemp[url];
    }
  });

  // Process Facebook and LinkedIn data similarly...

  return { tableDataTemp, pageNames };
}

function AdsWrapper() {
  const [tableData, setTableData] = useState({});

  const [adsData, setAdsData] = useState("");

  const [pageNamesData, setPageNamesData] = useState({});

  const [adsSearchData, setAdsSearchData] = useState("");

  const [exportList, setExportList] = useState({});

  const [loading, setLoading] = useState(false);
  const [criticalError, setCriticalError] = useState("");

  function adsFilterData(fetchedData: any) {
    // console.log("fetchedData", fetchedData);
    if (fetchedData == "dateRangeError") {
      setCriticalError(
        "START and END dates cannot be more than 90 days apart."
      );
    } else {
      setAdsData(fetchedData);
    }
  }

  function adsSearch(fetchedData: any) {
    // console.log("fetchedData", fetchedData);
    setLoading(true);
    setAdsSearchData(fetchedData);

    // setLoading to false after the data is loaded
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }

  // useEffect(() => {

  // }, [exportList]);

  useEffect(() => {
    if (!adsData) return;

    setLoading(true);

    fetchAllData(adsData).then((results) => {
      if (
        !results.pianoResults ||
        !results.googleAdsResults ||
        !results.facebookAdsResults ||
        !results.linkedinAdsResults
      ) {
        setCriticalError("Something went wrong, please try again.");
        setLoading(false);
        return;
      }

      setCriticalError("");

      const { tableDataTemp, pageNames } = processData(results, adsData);
      setTableData(tableDataTemp);
      setPageNamesData(pageNames);

      setLoading(false);
    });
  }, [adsData]);

  return (
    <>
      <Box
        sx={{
          opacity: loading ? "0.5" : "1",
          pointerEvents: loading ? "none" : "all",
        }}
      >
        <AdsFilter adsFilterData={adsFilterData} adsSearch={adsSearch} />
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </Box>
        )}
        {criticalError && criticalError != "" ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ width: "100%" }}>
              <Alert
                sx={{
                  mt: 0,
                  mb: 6,
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                }}
                icon={false}
                variant="filled"
                severity="error"
              >
                {criticalError}
              </Alert>
            </Box>
          </Box>
        ) : (
          <>
            <AdsTable
              tableData={tableData}
              pageNames={pageNamesData}
              adsSearch={adsSearchData}
              exportList={exportList}
              setExportList={setExportList} // Pass setExportList as a prop
            />
            {exportList && Object.keys(exportList).length > 0 && (
              <ExportFunction
                exportList={exportList}
                pageNames={pageNamesData}
                adsData={adsData}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default AdsWrapper;
