import React, { useState, useEffect, useMemo } from 'react';
import { Link } from "react-router-dom";


import { styled } from '@mui/material/styles';
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { createTheme } from '@mui/material/styles'
import {createStyles, Theme} from "@mui/material/styles";
// import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
// import PhoneInput from 'react-phone-input-2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CssBaseline from '@mui/material/CssBaseline';
import Tooltip from '@mui/material/Tooltip';
import {Grid, Box, Button, Container, Typography} from "@mui/material";
import Logo from "../assets/logo.svg";

import "../App.css";

import Table from "../components/table"


// import fetch from 'node-fetch'




export interface PhotoSignatureProps {
    fullName: string;
    position: string;
    phone: string;
    websitegroup: string;
    innerhtml: string;
}

interface State extends PhotoSignatureProps {
    copied: boolean;
    copiedSF: boolean;
}

const initialState: State = {
    fullName: "",
    position: "",
    phone: "",
    websitegroup: "services",
    copied: false,
    copiedSF: false,
    innerhtml: "",
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#000',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#6c757d' : '#6c757d',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));


  const themeLight = createTheme({
    typography: {
        fontFamily: 'Helvetica, sans-serif', // default Material-UI font
          h2: {
              fontSize: "22px",
              fontWeight: "bold",
          }
      },
      components: {
          MuiTableCell: {
              styleOverrides: {
                  root: {
                      borderColor: "#454a4f",
                      color: "#000000"
                  },
                  head: {
                    backgroundColor: '#E9ECEF',
                  }
              }
          },
          MuiTypography: {
              styleOverrides: {
                  root: {
                      borderColor: "#454a4f"
                  }
              }
          },
          MuiFormGroup: {
            styleOverrides: {
                root: {
                    color: "#000000"
                }
            }
          },
          MuiContainer: {
              styleOverrides: {
                  root: {
                      marginTop: "20px"
                  }
              }
          },
          MuiButton: {
              styleOverrides: {
                // Name of the slot
                root: {
                  // Some CSS
                  fontWeight: 'bold',
                },
              },
          },
          MuiTableSortLabel: {
              styleOverrides: {
                // Name of the slot
                root: {
                 
                
                  ' .MuiTableSortLabel-icon': {
                      color: 'black',
                      opacity: '0.2'
                  },
                  '&.Mui-active .MuiTableSortLabel-icon': {
                      color: 'black',
                      opacity: '1'
                  },
                }
              },
          },
      },
      palette: {
          mode: 'light',
          primary: {
              main: '#005BA5',
              contrastText: '#ffffff'
          },
          secondary: {
              main: '#FF7390'
          },
        
          text: {
              primary: "#000000"
          },
      
          background: {
            default: "#F8F9FA"
          }
      },
  });
  
  const themeDark = createTheme({
    typography: {
        fontFamily: 'Helvetica, sans-serif', // default Material-UI font
          h2: {
              fontSize: "22px",
              fontWeight: "bold",
          }
      },
      components: {
          MuiTableCell: {
              styleOverrides: {
                  root: {
                      borderColor: "#454a4f",
                      
                  },
                  head: {
                    backgroundColor: '#000000',
                  }
              }
          },
          
          MuiTypography: {
              styleOverrides: {
                  root: {
                      borderColor: "#454a4f"
                  }
              }
          },
          MuiContainer: {
              styleOverrides: {
                  root: {
                      marginTop: "20px"
                  }
              }
          },
          MuiButton: {
              styleOverrides: {
                // Name of the slot
                root: {
                  // Some CSS
                  fontWeight: 'bold',
                },
              },
          },
          MuiTableSortLabel: {
              styleOverrides: {
                // Name of the slot
                root: {

                
                  ' .MuiTableSortLabel-icon': {
                      color: 'white',
                      opacity: '0.2'
                  },
                  '&.Mui-active .MuiTableSortLabel-icon': {
                      color: 'white',
                      opacity: '1'
                  },
                }
              },
          },
      },
      palette: {
          mode: 'dark',
          primary: {
              main: '#26E6FF',
              contrastText: '#000000'
          },
          secondary: {
              main: '#FF7390'
          },
        //   arrows: {
        //     default: "#f5f5f5"
        //   },
        //   grey: {
        //       main: '#FF7390'
        //   },
          text: {
              primary: "#ffffff"
          },
          background: {
            default: "#121212"
          }
      },
  });


function Home() {

    const [mode, setMode] = useState("dark");

    const theme = useMemo(
        () =>
        createTheme({
            
          }),
        [mode]
      );



    // const classes = useStyles();
 

    const handleChangeMode = (event: React.ChangeEvent<HTMLInputElement>, property: any) => {
        console.log(property)
        if (property) {
            setMode('dark')
        } else {
            setMode('light')
        }
    };

    

    var userJson = localStorage.getItem("user");

    function testJSON(text: string | null) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    var loggedInUser = (testJSON(userJson)) ? JSON.parse(userJson || '') : null;


    // const photoUrlMaxLength = 1000;

    return (
        <ThemeProvider theme={mode == 'light' ? themeLight : themeDark}>
             <CssBaseline />
            <Container maxWidth="xl" className={mode == 'dark' ? 'dark-mode' : 'light-mode'}>
                <Grid
                    
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                   
                    sx={{ mt: 5, width: "100%" }}
                 
                >  
                    <Grid item xs={3} >
                        <Tooltip title={mode == 'dark' ? "Switch to light mode": "Switch to dark mode"}>
                            <FormControlLabel
                                control={<MaterialUISwitch size='small' sx={{ m: 0 }} defaultChecked onChange={handleChangeMode} />}
                                label=""
                                
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} >
                        <Link className={mode == 'dark' ? 'dark-logo' : 'light-logo'} to={"/"}>
                        <img className="logo" src={Logo} alt={"logo"}/>
                        </Link>
                        
                    </Grid>
                    <Grid item xs={3} >
                    </Grid>
                </Grid>
                  
                            
                       
                        <Typography sx={{mb: 2}} variant="h2" align="center">
                            Leads Dashboard
                        </Typography>
                      
                        <>
                            {loggedInUser && loggedInUser.email && loggedInUser.email.includes('@raconteur.net') ? (
                                <Table></Table>
                            ) : loggedInUser && loggedInUser.email && !loggedInUser.email.includes('@raconteur.net') ? (
                                <>
                                    <h3>Not a raconteur account</h3>
                                </>
                            ) : (
                                <>
                                	
                                    <Navigate to="/" />
                                </>
                            )}
                        </>

              
            </Container>
        </ThemeProvider>
       
    );
}

export default Home;
