import React, { useState } from "react";
import { Button, IconButton, Popover } from "@mui/material";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { TwitterPicker, AlphaPicker } from "react-color";
import {
  hexToHsva,
  hexToRgba,
  hsvaToRgbaString,
  rgbaToHexa,
} from "@uiw/color-convert";
import { useTheme } from "@mui/material/styles";

interface PickerProps {
  raconteurColors: string[];
  color: string;
  onChange: (color: string) => void;
}

const Picker: React.FC<PickerProps> = ({
  raconteurColors,
  color,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();

  return (
    <>
      <Button
        startIcon={<ColorizeIcon fontSize="small" />}
        onClick={handleClick}
        variant="text"
        // set the color to white (palette)
        sx={{
          color: theme.palette.mode === "dark" ? "white" : "black",
        }}
      >
        Change circle color
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ minWidth: "400px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ width: "100%", background: "white", padding: "20px" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <TwitterPicker
              color={color} // Use the color prop
              colors={raconteurColors}
              width="100%"
              onChange={(color) => {
                onChange(color.hex);
              }}
            />
            {/* <AlphaPicker
              color={color}
              width="100%"
              onChange={(newAlpha) => {
                var rgbaCol = hexToRgba(color);
                rgbaCol.a = newAlpha.rgb.a || 1;
                onChange(rgbaToHexa(rgbaCol));
              }}
            /> */}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Picker;
