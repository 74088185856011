import { useRef, useEffect, useLayoutEffect, useState, useMemo } from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Input } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload() {

    const API_URL = 'https://raconteur.lndo.site/mediahand.php'
        const API_METHOD = 'POST'
        const STATUS_IDLE = 0
        const STATUS_UPLOADING = 1

    const [files, setFiles] = useState([])
    const [status, setStatus] = useState(STATUS_IDLE)
    
    
    const uploadFiles = (data: FormData) => {
        setStatus(STATUS_UPLOADING);
        // console.log(data);
        fetch(API_URL, {
            method: API_METHOD,
            body: data,
        })
        .then((res) => res.json())
        .then((data) => console.log(data))
        .catch((err) => console.error(err))
        .finally(() => setStatus(STATUS_IDLE))
    }

    const handleRemove = (index: number) => () => {
        console.log(files);
        const dt = new DataTransfer()
  

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          if (index !== i)
            dt.items.add(file) // here you exclude the file. thus removing it.
        }
        
        setFiles(dt.files as any)
    
    };

    const packFiles = (files: any)=> {
        const data = new FormData();
     
        [...files].forEach((file, i) => {
            
      
                    data.append(`file-${i}`, file, file.name)

                
            
        })
        console.log(data)
        return data
    }

    const handleUploadClick = () => {
        if (files.length) {
            const data = packFiles(files)
            uploadFiles(data)
        }
    }

    const handleUploadClickFinal = () => {
        if (files.length) {
            console.log(files)
            const data = packFiles(files)
            console.log(data)
            uploadFiles(data)
        }
    }

    const [oneTwoThree, setOneTwoThree] = useState([]);

    const handleChangeType = (key: number, newAlignment: any) => {
        // oneTwoThree[key] = newAlignment;
        setOneTwoThree([...oneTwoThree]);

    };

    const renderFileList = () => (<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {[...files].map((f, i) => {
            const labelId = `checkbox-list-label-${i}`;
            const i2 = '103' + i;
            // console.log(f.type)
            // oneTwoThree[i] = f.type;
            return (
            <ListItem 
                key={i}
                secondaryAction={
                    <IconButton onClick={handleRemove(i)} edge="end" aria-label="delete">
                      <DeleteIcon  />
                    </IconButton>
                  }
                disablePadding
            >
                    <ListItemButton role={undefined}  dense>
                        {/* <ListItemIcon>
                            <DeleteIcon/>
                        </ListItemIcon> */}
                        <ListItemText sx={{color:"#ffffff"}} id={labelId} />
                        <ToggleButtonGroup
                            onChange={(event, value) => handleChangeType(i, value)}
                            color="primary"
                            value={oneTwoThree[i]}
                            exclusive
              
                            aria-label="Platform"
                            >
                            <ToggleButton value="text/html">Responsive HTML</ToggleButton>
                            <ToggleButton value="application/zip">Hype Animation</ToggleButton>
                        </ToggleButtonGroup>
                    </ListItemButton>
                    
            </ListItem>
        )})}
    </List>)

    const getButtonStatusText = () => (
        (status === STATUS_IDLE) ? 
            'Upload' : 
            <img src = "./load.svg" />
    )

   


    


  return (
    <>
        {files.length ?
            <>
                {renderFileList()}
                <Button 
                    component="label"
                    onClick={handleUploadClickFinal} 
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                >
                        Upload to assets.raconteur.net

                   
                </Button>
            </>

            : 

            <>
                <Button 
                    component="label"
                    onClick={handleUploadClick} 
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    disabled={status === STATUS_UPLOADING}>
                        {getButtonStatusText()}

                        <VisuallyHiddenInput 
                            type="file"  multiple
                            accept=".zip,.html" 
                            onChange={(e)=> setFiles(e.target.files as any)} />
                </Button>
            </>
        }
                
       
                
               
            
    </>
  );
}