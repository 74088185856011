import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import "./index.css";
import Leads from "./routes/Leads";
import Upload from "./routes/Upload";
import Ads from "./routes/Ads";
import Utm from "./routes/Utm";
import Headshots from "./routes/Headshots";
import Socials from "./routes/Social";
import TestDB from "./routes/TestDB";

// import dotenv from 'dotenv'
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "leads",
    element: <Leads />,
  },
  {
    path: "upload",
    element: <Upload />,
  },
  {
    path: "ads",
    element: <Ads />,
  },
  {
    path: "utm",
    element: <Utm />,
  },
  {
    path: "headshots",
    element: <Headshots />,
  },
  {
    path: "social",
    element: <Socials />,
  },
  {
    path: "test",
    element: <TestDB />,
  },
]);

// const myenv = dotenv.config().parsed;

const container = document.getElementById("root");
const root = createRoot(container as any);
root.render(
  <GoogleOAuthProvider clientId="187085282585-ak7216a3qps0p71diivi2robrfc2ubgd.apps.googleusercontent.com">
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </GoogleOAuthProvider>
);
