import React, { useState } from "react";

import Box from "@mui/material/Box";
// import IconButton from "@mui/material/IconButton";
// import Input from "@mui/material/Input";
// import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinkIcon from "@mui/icons-material/Link";
import SellIcon from "@mui/icons-material/Sell";
import {
  Chip,
  Typography,
  Paper,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// const darkTheme = createTheme({ palette: { mode: "dark" } });
// const lightTheme = createTheme({ palette: { mode: "light" } });

// remove spaces from string
function removeSpaces(str: string) {
  return str.replace(/\s+/g, "");
}

function checkUrl(url: string) {
  if (
    url.startsWith("https://") &&
    url.includes("www.raconteur.net") &&
    !url.includes("utm_") &&
    !url.includes("?") &&
    !url.includes("#")
  ) {
    return true;
  }

  return false;
}

function UtmGenerator() {
  const [loading, setLoading] = useState(false);
  const [adUrl, setAdUrl] = useState("https://www.raconteur.net");
  const [campaign, setCampaign] = useState("Raconteur_XX_YY(Z000)");
  // const theme = useTheme();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  let googleUrl =
    adUrl +
    "?utm_source=google" +
    "&utm_medium=cpc" +
    "&utm_campaign=" +
    removeSpaces(campaign);
  let facebookUrl =
    adUrl +
    "?utm_source=facebook" +
    "&utm_medium=cpc" +
    "&utm_campaign=" +
    removeSpaces(campaign);
  let twitterUrl =
    adUrl +
    "?utm_source=twitter" +
    "&utm_medium=cpc" +
    "&utm_campaign=" +
    removeSpaces(campaign);
  let linkedinUrl =
    adUrl +
    "?utm_source=linkedin" +
    "&utm_medium=cpc" +
    "&utm_campaign=" +
    removeSpaces(campaign);

  // console.log(theme);

  function copyToClipboard(text: string) {
    setLoading(true);
    navigator.clipboard.writeText(text);
    setMessage("Copied to clipboard");

    setTimeout(() => {
      setLoading(false);
      setMessage("");
    }, 3000);
  }

  return (
    <>
      <Box
        mt={6}
        sx={{
          opacity: loading ? "0.5" : "1",
          pointerEvents: loading ? "none" : "all",
          transition: "all .2s ease-in-out",
          display: "flex",
          alignContent: "flex-start",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            width: "calc(50% - 80px)",
            marginRight: "80px",
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: "100%",
              borderRadius: "25px",
              padding: "30px",
              paddingTop: "10px",
            }}
          >
            <Chip
              label={
                <Typography fontWeight={"bold"}>Campaign settings</Typography>
              }
              color="primary"
              sx={{
                marginTop: "-50px",
                marginLeft: "20px",
              }}
            />

            <FormControl sx={{ mt: 0 }} fullWidth variant="outlined">
              <OutlinedInput
                id="adUrl"
                value={adUrl}
                // on change check if url is valid
                onChange={(e) => {
                  if (checkUrl(e.target.value)) {
                    setAdUrl(e.target.value);
                  } else {
                    setError("Please enter a valid url");
                    setAdUrl(adUrl);
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                }
                aria-describedby="adUrl-helper-text"
                inputProps={{
                  "aria-label": "Article url",
                }}
              />
              <FormHelperText id="adUrl-helper-text">
                Article URL
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ mt: 3 }} fullWidth variant="outlined">
              <OutlinedInput
                id="campaign"
                value={campaign}
                // on change check if campaign is not empty
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setCampaign(e.target.value);
                  } else {
                    setError("Please enter a campaign");
                    setCampaign(campaign);
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SellIcon />
                  </InputAdornment>
                }
                aria-describedby="campaign-helper-text"
                inputProps={{
                  "aria-label": "UTM Campaign",
                }}
              />
              <FormHelperText id="campaign-helper-text">
                UTM Campaign
              </FormHelperText>
            </FormControl>
          </Paper>
        </Box>
        <Box
          sx={{
            width: "50%",
            pointerEvents: loading ? "none" : "all",
          }}
        >
          <Paper
            sx={{
              width: "100%",
              // backgroundColor: theme.palette.grey[900],
              borderRadius: "25px",
              padding: "30px",
              paddingTop: "10px",
              // border: "1px solid" + theme.palette.primary.main,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Chip
                label={
                  <Typography
                    fontWeight={"bold"}
                    color={"black"}
                    display={"block"}
                    textAlign={"center"}
                  >
                    Genrated URLs
                  </Typography>
                }
                color="primary"
                sx={{
                  marginTop: "-30px",
                  // marginLeft: "20px",
                  // marginLeft: "100%",
                  // transform: "translateX(calc(100%))",
                }}
              />
            </div>

            <Tooltip
              title="Click to copy"
              placement="top-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -6],
                      },
                    },
                  ],
                },
              }}
            >
              <FormControl
                sx={{ mt: 3, cursor: "pointer" }}
                fullWidth
                variant="outlined"
                onClick={() => copyToClipboard(googleUrl)}
              >
                <OutlinedInput
                  id="googleUrl"
                  value={googleUrl}
                  disabled={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <ContentCopyIcon />
                    </InputAdornment>
                  }
                  aria-describedby="adUrl-helper-text"
                  inputProps={{
                    "aria-label": "Google Ads",
                  }}
                  sx={{ pointerEvents: "none" }}
                />
                <FormHelperText id="adUrl-helper-text">
                  <Typography
                    variant="overline"
                    fontWeight={"bold"}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      background: "#eb4034",
                      borderRadius: "5px",
                      padding: "2px 7px",
                    }}
                  >
                    Google Ads
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Tooltip>
            <Tooltip
              title="Click to copy"
              placement="top-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -6],
                      },
                    },
                  ],
                },
              }}
            >
              <FormControl
                sx={{ mt: 4, cursor: "pointer" }}
                fullWidth
                variant="outlined"
                onClick={() => copyToClipboard(facebookUrl)}
              >
                <OutlinedInput
                  id="facebookUrl"
                  value={facebookUrl}
                  disabled={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <ContentCopyIcon />
                    </InputAdornment>
                  }
                  aria-describedby="adUrl-helper-text"
                  inputProps={{
                    "aria-label": "Facebook Ads",
                  }}
                  sx={{ pointerEvents: "none" }}
                />
                <FormHelperText id="adUrl-helper-text">
                  <Typography
                    variant="overline"
                    fontWeight={"bold"}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      background: "#3b5998",
                      borderRadius: "5px",
                      padding: "2px 7px",
                    }}
                  >
                    Facebook Ads
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Tooltip>
            <Tooltip
              title="Click to copy"
              placement="top-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -6],
                      },
                    },
                  ],
                },
              }}
            >
              <FormControl
                sx={{ mt: 4, cursor: "pointer" }}
                fullWidth
                variant="outlined"
                onClick={() => copyToClipboard(twitterUrl)}
              >
                <OutlinedInput
                  id="twitterUrl"
                  value={twitterUrl}
                  disabled={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <ContentCopyIcon />
                    </InputAdornment>
                  }
                  aria-describedby="adUrl-helper-text"
                  inputProps={{
                    "aria-label": "Twitter Ads",
                  }}
                  sx={{ pointerEvents: "none" }}
                />
                <FormHelperText id="adUrl-helper-text">
                  <Typography
                    variant="overline"
                    fontWeight={"bold"}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      background: "#1da1f2",
                      borderRadius: "5px",
                      padding: "2px 7px",
                    }}
                  >
                    Twitter Ads
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Tooltip>
            <Tooltip
              title="Click to copy"
              placement="top-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -6],
                      },
                    },
                  ],
                },
              }}
            >
              <FormControl
                sx={{ mt: 4, cursor: "pointer" }}
                fullWidth
                variant="outlined"
                onClick={() => copyToClipboard(linkedinUrl)}
              >
                <OutlinedInput
                  id="linkedinUrl"
                  value={linkedinUrl}
                  disabled={true}
                  startAdornment={
                    <InputAdornment position="start">
                      <ContentCopyIcon />
                    </InputAdornment>
                  }
                  aria-describedby="adUrl-helper-text"
                  inputProps={{
                    "aria-label": "Linkedin Ads",
                  }}
                  sx={{ pointerEvents: "none" }}
                />
                <FormHelperText id="adUrl-helper-text">
                  <Typography
                    variant="overline"
                    fontWeight={"bold"}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      background: "#0077b5",
                      borderRadius: "5px",
                      padding: "2px 7px",
                    }}
                  >
                    Linkedin Ads
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Tooltip>
          </Paper>
        </Box>
      </Box>
      {message !== "" && (
        <Snackbar
          open={true}
          // autoHideDuration={4000}
          message={message}
          // onClose={() => setMessage("")}
        >
          <Alert
            // onClose={() => setMessage("")}
            icon={false}
            severity="info"
            variant="filled"
            sx={{
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {error !== "" && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          message={error}
          onClose={() => setError("")}
        >
          <Alert
            onClose={() => setError("")}
            icon={false}
            severity="error"
            variant="filled"
            sx={{
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default UtmGenerator;
