import React, { useState, memo, useCallback, useMemo } from "react";
import debounce from "lodash/debounce";
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  Fab,
  FormGroup,
  FormControlLabel,
  TextField,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Drawer,
  Slide,
  Snackbar,
  Alert,
  // CircularProgress,
  InputAdornment,
} from "@mui/material";
import {
  Share as ShareIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";

import { TransitionProps } from "@mui/material/transitions";

import ChartPreview from "./adsChartPreview";

import axios from "axios";
// import { set } from "lodash";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  exportList: {
    [key: string]: any;
  };
  pageNames: {
    [key: string]: any;
  };
  adsData: string;
};

function Capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formatNumber(number: number) {
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

const ExportFunction = memo(
  ({ exportList: initialExportList, pageNames, adsData: dateRange }: Props) => {
    // const [exportList, setExportList] = useState(initialExportList);

    const [open, setOpen] = useState(false);
    const [pcaExportList, setPcaExportList] = useState({});
    const [error, setError] = useState("");
    const [exportSettings, setExportSettings] = useState({
      pageViews: true,
      pageViewBreakDown: true,
      pageViewKpi: 4000,
      clicks: true,
      impressions: true,
      cost: false,
      ctr: true,
      registered: true,
      registeredBreakDown: true,
      sessionDuration: true,
      sessionDurationBreakDown: true,
      screenShoorUrl: Object.keys(initialExportList)[0],
    });

    const [screenShotInputValue, setScreenShotInputValue] = useState(
      exportSettings.screenShoorUrl
    );

    const [imageDriveId, setImageDriveId] = useState("");
    const [generatedPercentages, setGeneratedPercentages] = useState({
      timePercentage: 0,
      ctr: 0,
      articleViews: 0,
    });

    const [averages, setAverages] = useState<any>({});

    const setImageDriveIdParent = (childData: any) => {
      // console.log(childData);
      // console.log("setImageDriveIdParent", childData);
      if (childData.imageDriveId) {
        setImageDriveId(childData.imageDriveId);
        setExportText("Export slide");
      } else {
        setImageDriveId("none");
        setExportText("Export slide without screenshot");
      }
    };

    const setGeneratedPercentagesParent = (childData: any) => {
      // console.log(childData);
      setGeneratedPercentages(childData);
      // console.log(generatedPercentages);
    };

    const setAveragesParent = (childData: any) => {
      setAverages(childData);
    };

    const [disableExport, setDisableExport] = useState(false);
    const [exportText, setExportText] = useState(
      "Export slide without screenshot"
    );

    // useEffect(() => {
    //   console.log(generatedPercentages);
    // }, [generatedPercentages]);

    const countSelected = (settings: any) => {
      const keys = [
        "pageViews",
        "clicks",
        "impressions",
        "cost",
        "ctr",
        "registered",
        "sessionDuration",
      ];
      return keys.filter((key) => settings[key]).length;
    };

    const handleChangeExportAll = useCallback((value: any, path: string) => {
      setExportSettings((prevExportSettings) => {
        const newSettings = { ...prevExportSettings, [path]: value };
        const selectedCount = countSelected(newSettings);

        // Check constraints
        if (selectedCount >= 3 && selectedCount <= 6) {
          return newSettings;
        }
        setError("Please select 3 to 6 metrics");
        // Optionally, you can add some feedback here if the constraints are not met
        return prevExportSettings; // No change if constraints are not met
      });
    }, []);

    const exportTest = useMemo(() => {
      const overallTotal = {
        page_views: 0,
        clicks: 0,
        impressions: 0,
        cost: 0,
      };

      const initializedExportList = JSON.parse(
        JSON.stringify(initialExportList)
      );

      Object.keys(initializedExportList).forEach((key) => {
        let checkedCampaignsTotal = {
          page_views: 0,
          clicks: 0,
          impressions: 0,
          cost: 0,
        };

        Object.keys(initializedExportList[key]).forEach((campaignName) => {
          Object.keys(initializedExportList[key][campaignName]).forEach(
            (utmSource) => {
              if (
                typeof initializedExportList[key][campaignName][utmSource] ===
                "object"
              ) {
                if (
                  !initializedExportList[key][campaignName][
                    utmSource
                  ].hasOwnProperty("checked")
                ) {
                  initializedExportList[key][campaignName][utmSource].checked =
                    true;
                }

                if (
                  initializedExportList[key][campaignName][utmSource].checked
                ) {
                  checkedCampaignsTotal.page_views +=
                    Number(
                      initializedExportList[key][campaignName][utmSource][
                        "Page views"
                      ]
                    ) || 0;
                  checkedCampaignsTotal.clicks +=
                    Number(
                      initializedExportList[key][campaignName][utmSource].clicks
                    ) || 0;
                  checkedCampaignsTotal.impressions +=
                    Number(
                      initializedExportList[key][campaignName][utmSource]
                        .impressions
                    ) || 0;
                  checkedCampaignsTotal.cost +=
                    Number(
                      initializedExportList[key][campaignName][utmSource].cost
                    ) || 0;

                  if (utmSource !== "Campaign total") {
                    overallTotal.page_views +=
                      Number(
                        initializedExportList[key][campaignName][utmSource][
                          "Page views"
                        ]
                      ) || 0;
                    overallTotal.clicks +=
                      Number(
                        initializedExportList[key][campaignName][utmSource]
                          .clicks
                      ) || 0;
                    overallTotal.impressions +=
                      Number(
                        initializedExportList[key][campaignName][utmSource]
                          .impressions
                      ) || 0;
                    overallTotal.cost +=
                      Number(
                        initializedExportList[key][campaignName][utmSource].cost
                      ) || 0;
                  }
                }
              }
            }
          );
        });

        initializedExportList[key]["Page total"] = checkedCampaignsTotal;
      });

      // setExportList(initializedExportList);
      setPcaExportList(overallTotal);
      return initializedExportList;
    }, [initialExportList]);

    const [exportList, setExportList] = useState(exportTest);

    // useEffect(() => {
    //   console.log("yo");

    // }, [initialExportList]);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const fetchSaveAds = async (
      pcaExportList: any,
      exportList: any,
      dateRange: string,
      exportSettings: any,
      base64Image: string,
      generatedPercentages: any,
      averages: any
    ) => {
      try {
        setDisableExport(true);
        setExportText("Creating export...");
        const presentationId = await axios
          .post("/.netlify/functions/adsCopy")
          .then((res) => res.data.copySlideId);

        // console.log(presentationId);
        setExportText("Saving screenshot...");
        console.log(base64Image);

        const response = await axios.post("/.netlify/functions/adsSave", {
          pcaExportList,
          exportList,
          dateRange,
          exportSettings,
          imageDriveId,
          generatedPercentages,
          averages,
          presentationId,
        });

        setExportText("Downloading...");

        // Create a link element
        const url = response.data.export;
        // console.log(url);
        const link = document.createElement("a");
        link.href = url;

        // Append to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();
        // Remove the link element
        document.body.removeChild(link);

        const deleteSlide = await axios.post("/.netlify/functions/adsDelete", {
          fileId: response.data.fileId,
        });

        // setDisableExport(true);
        setExportText("Downloaded");
        // after 5 seconds change the export text to "Save for Google slide"
        setTimeout(() => {
          setDisableExport(false);
          setExportText("Export slide");
        }, 5000);

        console.log(deleteSlide);
      } catch (error) {
        setDisableExport(false);
        setExportText("Something went wrong, try again");
        setTimeout(() => {
          setExportText("Export slide");
        }, 5000);

        console.log(error);
      }
    };

    // const handleSwitchChange = useCallback(
    //   (key: string, campaignName: string, utmSource: string) => {
    //     setExportList((prevExportList) => {});
    //   },
    //   []
    // );

    const debouncedHandleSwitchChange = useCallback(
      debounce((key, campaignName, utmSource) => {
        setExportList((prevExportList: any) => {
          const updatedExportList = JSON.parse(JSON.stringify(prevExportList));

          if (!updatedExportList[key][campaignName]) {
            updatedExportList[key][campaignName] = {};
          }
          updatedExportList[key][campaignName][utmSource] = {
            ...updatedExportList[key][campaignName][utmSource],
            checked: !updatedExportList[key][campaignName][utmSource]?.checked,
          };

          const overallTotal = {
            page_views: 0,
            clicks: 0,
            impressions: 0,
            cost: 0,
          };

          Object.keys(updatedExportList).forEach((key) => {
            Object.keys(updatedExportList[key]).forEach((campaignName) => {
              Object.keys(updatedExportList[key][campaignName]).forEach(
                (utmSource) => {
                  if (
                    typeof updatedExportList[key][campaignName][utmSource] ===
                    "object"
                  ) {
                    if (
                      updatedExportList[key][campaignName][utmSource].checked
                    ) {
                      if (utmSource !== "Campaign total") {
                        overallTotal.page_views +=
                          Number(
                            updatedExportList[key][campaignName][utmSource][
                              "Page views"
                            ]
                          ) || 0;
                        overallTotal.clicks +=
                          Number(
                            updatedExportList[key][campaignName][utmSource]
                              .clicks
                          ) || 0;
                        overallTotal.impressions +=
                          Number(
                            updatedExportList[key][campaignName][utmSource]
                              .impressions
                          ) || 0;
                        overallTotal.cost +=
                          Number(
                            updatedExportList[key][campaignName][utmSource].cost
                          ) || 0;
                      }
                    }
                  }
                }
              );
            });
          });

          setPcaExportList(overallTotal);
          return updatedExportList;
        });
      }, 300),
      []
    ); // Adjust debounce time as per your requirement

    const handleSwitchChange = (
      key: string,
      campaignName: string,
      utmSource: string
    ) => {
      debouncedHandleSwitchChange(key, campaignName, utmSource);
    };

    const handleScreenShotUpdate = (e: any) => {
      e.preventDefault();
      if (screenShotInputValue === exportSettings.screenShoorUrl) {
        // setBase64ImageParent(null);
        handleChangeExportAll(
          screenShotInputValue + "?no-cache=true",
          "screenShoorUrl"
        );
      } else {
        handleChangeExportAll(screenShotInputValue, "screenShoorUrl");
      }
    };

    return (
      <Box
        p={2}
        sx={{ position: "fixed", bottom: 0, right: 0 }}
        className="export-function"
      >
        <Fab color="primary" variant="extended" onClick={handleClickOpen}>
          <ShareIcon sx={{ mr: 1 }} />
          <Typography fontWeight={"bold"} variant="body2">
            Export {Object.keys(exportList).length} Campaign
            {Object.keys(exportList).length > 1 ? "s" : ""}
          </Typography>
        </Fab>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: 1000 }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Export campaigns
                </Typography>
                {generatedPercentages &&
                generatedPercentages.articleViews &&
                generatedPercentages.articleViews > 0 ? (
                  <Button
                    autoFocus
                    color="inherit"
                    disabled={disableExport}
                    onClick={() =>
                      fetchSaveAds(
                        pcaExportList,
                        exportList,
                        dateRange,
                        exportSettings,
                        imageDriveId,
                        generatedPercentages,
                        averages
                      )
                    }
                  >
                    {exportText}
                  </Button>
                ) : (
                  <Box sx={{ display: "flex" }}>
                    <Button color="inherit" disabled={true}>
                      Loading...
                    </Button>
                  </Box>
                )}
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                maxWidth: "calc(100% - 800px)",
                width: "100%",
                flexShrink: 0,
                zIndex: 3,
                "& .MuiDrawer-paper": {
                  width: "calc(100% - 800px)",
                  boxSizing: "border-box",
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <Toolbar />
              <Box sx={{ mb: 2, p: 3 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Export settings
                </Typography>
                <Divider />
                <FormGroup>
                  <TextField
                    id="pageViewKpi"
                    label="URL for screenshot"
                    value={screenShotInputValue} // I would like to use this as starting value
                    onChange={(e) => setScreenShotInputValue(e.target.value)}
                    // onSubmit={(e) => (
                    //   e.preventDefault(),
                    //   setExportSettings((prevSettings) => ({
                    //     ...prevSettings,
                    //     screenShoorUrl: screenShotInputValue,
                    //   }))
                    // )} // here I want to handle the exportAllChange
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RefreshIcon
                            onClick={handleScreenShotUpdate}
                            sx={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mt: 4 }}
                  />

                  <Divider
                    textAlign="left"
                    sx={{
                      mt: 4,
                      fontWeight: "bold",
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                  >
                    Article views
                  </Divider>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      mt: 1.5,
                      pl: 1,
                      minHeight: "35px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.pageViews}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.pageViews ? false : true,
                            "pageViews"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          Article views
                        </Typography>
                      }
                    />

                    {exportSettings.pageViews && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={exportSettings.pageViewBreakDown}
                            color="success"
                            onChange={handleChangeExportAll.bind(
                              null,
                              exportSettings.pageViewBreakDown ? false : true,
                              "pageViewBreakDown"
                            )}
                            size="small"
                            inputProps={{
                              "aria-label": "Hide already contacted",
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" fontWeight="bold">
                            Breakdown
                          </Typography>
                        }
                      />
                    )}
                    {
                      // create an input field for the page view KPI
                      exportSettings.pageViews &&
                        exportSettings.pageViewBreakDown && (
                          <TextField
                            id="pageViewKpi"
                            label=""
                            value={exportSettings.pageViewKpi}
                            // on change update the value of the textField by updating the value with the user input
                            onChange={(e) =>
                              handleChangeExportAll(
                                e.target.value,
                                "pageViewKpi"
                              )
                            }
                            variant="standard"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography variant="body2" fontWeight="bold">
                                    KPI
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                            // width={"100px"}
                            sx={{ width: "100px" }}
                          />
                        )
                    }
                  </Box>

                  <Divider
                    textAlign="left"
                    sx={{
                      mt: 4,
                      fontWeight: "bold",
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                  >
                    Session duration
                  </Divider>

                  <Box sx={{ display: "flex", flexWrap: "wrap", mt: 3, pl: 1 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.sessionDuration}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.sessionDuration ? false : true,
                            "sessionDuration"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          Avg. Session
                        </Typography>
                      }
                    />
                    {exportSettings.sessionDuration && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={exportSettings.sessionDurationBreakDown}
                            color="success"
                            onChange={handleChangeExportAll.bind(
                              null,
                              exportSettings.sessionDurationBreakDown
                                ? false
                                : true,
                              "sessionDurationBreakDown"
                            )}
                            size="small"
                            inputProps={{
                              "aria-label": "Hide already contacted",
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            sx={{ mr: 2 }}
                          >
                            Industry breakdown
                          </Typography>
                        }
                      />
                    )}
                  </Box>

                  <Divider
                    textAlign="left"
                    sx={{
                      mt: 4,
                      fontWeight: "bold",
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                  >
                    Registered percentage metric
                  </Divider>

                  <Box sx={{ display: "flex", flexWrap: "wrap", mt: 3, pl: 1 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.registered}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.registered ? false : true,
                            "registered"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          "Heaf of" / above
                        </Typography>
                      }
                    />
                    {exportSettings.registered && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={exportSettings.registeredBreakDown}
                            color="success"
                            onChange={handleChangeExportAll.bind(
                              null,
                              exportSettings.registeredBreakDown ? false : true,
                              "registeredBreakDown"
                            )}
                            size="small"
                            inputProps={{
                              "aria-label": "Hide already contacted",
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            sx={{ mr: 2 }}
                          >
                            Single roles breakdown
                          </Typography>
                        }
                      />
                    )}
                  </Box>

                  <Divider
                    textAlign="left"
                    sx={{
                      mt: 4,
                      fontWeight: "bold",
                      fontSize: "12px",
                      textTransform: "uppercase",
                    }}
                  >
                    Other Metrics
                  </Divider>

                  <Box sx={{ display: "flex", flexWrap: "wrap", mt: 3, pl: 1 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.impressions}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.impressions ? false : true,
                            "impressions"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          Impressions
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.clicks}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.clicks ? false : true,
                            "clicks"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          Clicks
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.ctr}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.ctr ? false : true,
                            "ctr"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          CTR
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={exportSettings.cost}
                          onChange={handleChangeExportAll.bind(
                            null,
                            exportSettings.cost ? false : true,
                            "cost"
                          )}
                          size="small"
                          inputProps={{
                            "aria-label": "Hide already contacted",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mr: 2 }}
                        >
                          Cost
                        </Typography>
                      }
                    />
                  </Box>
                </FormGroup>
              </Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" component="div">
                  Campaigns settings
                </Typography>
              </Box>
              <List>
                {Object.keys(exportList).map((key, i) => {
                  // Check if the key exists in pageNames object
                  const pageName = pageNames[key] || "Page Not Found"; // Default to 'Page Not Found' if key not found
                  return (
                    <Accordion key={i} sx={{ pl: 1, pr: 1 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box>
                          <Typography variant="body1" display={"block"}>
                            {pageName}
                          </Typography>

                          <Typography variant="caption" display={"block"}>
                            {key}
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        {Object.keys(exportList[key]).map(
                          (campaignName, i2) => {
                            // Check if the campaignName is not "Page total"
                            if (campaignName !== "Page total") {
                              return Object.keys(
                                exportList[key][campaignName]
                              ).map((utmSource, i3) => {
                                // Render the utmSource if it exists in the object and is not "Campaign total"
                                if (utmSource !== "Campaign total") {
                                  // if utmSource is also inside finalExport, const exportThis = true else const exportThis = false
                                  const exportThis =
                                    exportList[key][campaignName][utmSource]
                                      ?.checked ?? true;

                                  return (
                                    <div
                                      key={i3}
                                      style={{ marginBottom: "20px" }}
                                    >
                                      <Divider>
                                        <Box sx={{ display: "flex" }}>
                                          <Chip
                                            label={Capitalize(utmSource)}
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                              fontWeight: "bold",
                                              mb: 1.2,
                                              mt: 1.2,
                                            }}
                                          />
                                          <FormGroup>
                                            <Typography
                                              variant="caption"
                                              display="flex"
                                              alignItems="center"
                                              fontWeight="bold"
                                              mt={1}
                                              ml={2}
                                            >
                                              <Switch
                                                checked={exportThis}
                                                onChange={() =>
                                                  handleSwitchChange(
                                                    key,
                                                    campaignName,
                                                    utmSource
                                                  )
                                                }
                                                size="small"
                                                inputProps={{
                                                  "aria-label":
                                                    "Hide already contacted",
                                                }}
                                              />
                                              {/* {contactedSelection ? "Already " : "Hide already contacted"} */}
                                              Include
                                            </Typography>
                                          </FormGroup>
                                        </Box>
                                      </Divider>
                                      <Box
                                        mt={"4"}
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          width: "100%",
                                        }}
                                      >
                                        {
                                          // foreach element in utmSource (as object)
                                          Object.keys(
                                            exportList[key][campaignName][
                                              utmSource
                                            ]
                                          ).map((campaignMetric, i4) => {
                                            if (campaignMetric !== "checked") {
                                              return (
                                                <div
                                                  key={i4}
                                                  style={{ width: "25%" }}
                                                >
                                                  <Typography
                                                    variant="body1"
                                                    display="block"
                                                    lineHeight={1.5}
                                                    fontWeight={"bold"}
                                                  >
                                                    {campaignMetric === "cost"
                                                      ? " £"
                                                      : ""}
                                                    {formatNumber(
                                                      exportList[key][
                                                        campaignName
                                                      ][utmSource][
                                                        campaignMetric
                                                      ]
                                                    )}
                                                  </Typography>
                                                  <Typography
                                                    variant="overline"
                                                    display="block"
                                                    lineHeight={1.5}
                                                    fontWeight={"bold"}
                                                  >
                                                    {Capitalize(campaignMetric)}
                                                  </Typography>
                                                </div>
                                              );
                                            }
                                          })
                                        }
                                      </Box>
                                    </div>
                                  );
                                }
                              });
                            }
                            return null; // Return null if campaignName is "Page total"
                          }
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: "background.default",
                p: 3,
                width: "800px",
              }}
            >
              <Toolbar />
              {Object.keys(pcaExportList).length > 0 && (
                <ChartPreview
                  pcaExportList={pcaExportList}
                  exportList={exportList}
                  dateRange={dateRange}
                  exportSettings={exportSettings}
                  imageDriveIdParent={setImageDriveIdParent}
                  generatedPercentagesParent={setGeneratedPercentagesParent}
                  generatedAveragesParent={setAveragesParent}
                />
              )}
            </Box>
          </Box>
          {error && error !== "" && (
            <Snackbar
              open={true}
              autoHideDuration={4000}
              message={error}
              onClose={() => setError("")}
            >
              <Alert
                onClose={() => setError("")}
                severity="info"
                variant="filled"
                sx={{ width: "100%", color: "white", fontWeight: "bold" }}
              >
                {error}
              </Alert>
            </Snackbar>
          )}
        </Dialog>
      </Box>
    );
  }
);

export default ExportFunction;
