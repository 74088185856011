import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ThemeProvider } from "@mui/material/styles";
import { createStyles, Theme } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Box, Container, Typography, Grid } from "@mui/material";
import Logo from "./assets/logo.svg";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import GoogleIcon from "@mui/icons-material/Google";
import CssBaseline from "@mui/material/CssBaseline";
import { set } from "lodash";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const theme = createTheme({
  typography: {
    fontFamily: "Helvetica, sans-serif", // default Material-UI font
    h2: {
      fontSize: "22px",
      fontWeight: "bold",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: "20px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontWeight: "bold",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          " .MuiTableSortLabel-icon": {
            color: "white",
            opacity: "0.2",
          },
          "&.Mui-active .MuiTableSortLabel-icon": {
            color: "white",
            opacity: "1",
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#26E6FF",
    },
    secondary: {
      main: "#fd2341",
    },
    backtround: {
      default: "#121212",
    },
  },
});

function App() {
  const [user, setUser] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
  );

  const loginButton = useGoogleLogin({
    scope: [
      "openid",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/spreadsheets.readonly",
      "https://www.googleapis.com/auth/spreadsheets",
    ].join(" "),
    onSuccess: async (responseLogin) => {
      // console.log(responseLogin);
      localStorage.setItem("accessToken", JSON.stringify(responseLogin));

      const profileInfo = await axios.post("/.netlify/functions/googletokens", {
        // http://localhost:3001/auth/google backend that will exchange the code
        responseLogin,
      });

      // foreach element in profileInfo decode the values and create a new object

      const decodedName = decodeURIComponent(profileInfo.data.encodedName);
      const decodedEmail = decodeURIComponent(profileInfo.data.encodedEmail);
      const decodedAccessToken = decodeURIComponent(
        profileInfo.data.encodedAccessToken
      );
      const decodedRefreshToken = decodeURIComponent(
        profileInfo.data.encodedRefreshToken
      );
      const decodedExpiryDate = decodeURIComponent(
        profileInfo.data.encodedExpiryDate
      );

      // create a new object with the decoded values
      const newObject = {
        name: decodedName,
        email: decodedEmail,
        access_token: decodedAccessToken,
        refresh_token: decodedRefreshToken,
        expiry_date: decodedExpiryDate,
      };
      // set the new object in the local storage
      localStorage.setItem("user", JSON.stringify(newObject));
      setUser(newObject);
      console.log(newObject);
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: "auth-code",
  });

  //   console.log(user.email);

  const designEmails = [
    "ra@raconteur.net",
    "tw@raconteur.net",
    "kj@raconteur.net",
    "sm@raconteur.net",
    "cm@raconteur.net",
  ];

  function logOut() {
    googleLogout();
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    setUser([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 5 }}
      >
        <Grid item md={12} sx={{ width: "100%" }}>
          <img className="logo" src={Logo} alt={"logo"} />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h2" gutterBottom align="center">
            Tools
          </Typography>
          <Box
            container="true"
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="target"
          >
            {user && user.email && user.email.includes("@raconteur.net") ? (
              <Box
                container="true"
                spacing={0}
                direction="column"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                sx={{ mt: 3 }}
                className="target"
              >
                <Link to="/leads">
                  <Button
                    sx={{ mb: 1 }}
                    display="block"
                    fontWeight="bold"
                    variant="contained"
                    endIcon={<OpenInNewIcon size="sm" />}
                  >
                    Leads Dashboard
                  </Button>
                </Link>

                <Typography
                  sx={{ mb: 3 }}
                  display="block"
                  fontWeight="bold"
                  color="white"
                  variant="caption"
                  align="center"
                >
                  A one-view list of all marketing leads with filters and
                  display options.
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Link to="/ads">
                    <Button
                      sx={{ mb: 1 }}
                      display="block"
                      fontWeight="bold"
                      variant="contained"
                      endIcon={<OpenInNewIcon size="sm" />}
                    >
                      Paid Traffic Dashboard
                    </Button>
                  </Link>

                  <Link to="/utm">
                    <Button
                      sx={{ mb: 1 }}
                      display="block"
                      fontWeight="bold"
                      variant="contained"
                      endIcon={<OpenInNewIcon size="sm" />}
                    >
                      UTM Link generator
                    </Button>
                  </Link>
                </Stack>

                <Typography
                  sx={{ mb: 3 }}
                  display="block"
                  fontWeight="bold"
                  color="white"
                  variant="caption"
                  align="center"
                >
                  Paid campaigns analytics and reporting, and a generator for
                  links to use in your marketing campaigns.
                </Typography>

                {user && user.email && designEmails.includes(user.email) ? (
                  <>
                    <Link to="/headshots">
                      <Button
                        sx={{ mb: 1 }}
                        display="block"
                        fontWeight="bold"
                        variant="contained"
                        endIcon={<OpenInNewIcon size="sm" />}
                      >
                        Raconteur Circle Headshots
                      </Button>
                    </Link>

                    <Typography
                      sx={{ mb: 3 }}
                      display="block"
                      fontWeight="bold"
                      color="white"
                      variant="caption"
                      align="center"
                    >
                      A background remover for your headshots to make them
                      Raconteur ready.
                    </Typography>
                  </>
                ) : null}

                <a
                  target="_blank"
                  href="https://raconteur-email-signatures.netlify.app/"
                >
                  <Button
                    sx={{ mb: 1 }}
                    display="block"
                    fontWeight="bold"
                    variant="contained"
                    endIcon={<OpenInNewIcon size="sm" />}
                  >
                    Email Signature Generator
                  </Button>
                </a>

                <Typography
                  sx={{ mb: 3 }}
                  display="block"
                  fontWeight="bold"
                  color="white"
                  variant="caption"
                  align="center"
                >
                  Generate your signature for Gmail, Outlook, Apple and
                  Salesloft.
                </Typography>

                <a target="_blank" href="https://services.raconteur.net/">
                  <Button
                    sx={{ mb: 1 }}
                    display="block"
                    fontWeight="bold"
                    variant="contained"
                    endIcon={<OpenInNewIcon size="sm" />}
                  >
                    Raconteur Creates
                  </Button>
                </a>

                <Typography
                  sx={{ mb: 3 }}
                  display="block"
                  fontWeight="bold"
                  color="white"
                  variant="caption"
                  align="center"
                >
                  Services website with case studies, live content and all
                  products.
                </Typography>

                <Chip label={user.name} />

                <Typography
                  color="white"
                  display="block"
                  variant="caption"
                  align="center"
                >
                  {user.email}
                </Typography>

                <Button
                  color="warning"
                  sx={{ mt: 2, fontSize: 10 }}
                  align="center"
                  onClick={logOut}
                  variant="outlined"
                >
                  Log out
                </Button>
                <Divider sx={{ mt: 3 }} />

                <Alert
                  sx={{ mt: 3, display: "inline-block" }}
                  icon={false}
                  mt={6}
                  variant="outlined"
                  severity="info"
                  textAlign="center"
                  align="center"
                >
                  This is for internal use only, tested on Google Chrome without
                  AdBlocks.
                </Alert>
              </Box>
            ) : user && user.email && !user.email.includes("@raconteur.net") ? (
              <Box
                container="true"
                spacing={0}
                direction="column"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                className="target"
              >
                <Typography variant="h4" gutterBottom align="center">
                  Not a raconteur account
                </Typography>
                <Button align="center" onClick={logOut} variant="outlined">
                  Log out
                </Button>
              </Box>
            ) : (
              <>
                <Box
                  container="true"
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  textAlign="center"
                  justifyContent="center"
                  sx={{ mt: 4 }}
                  className="target"
                >
                  <Button
                    onClick={() => loginButton()}
                    variant="contained"
                    width="250px"
                    startIcon={<GoogleIcon />}
                  >
                    Sign in with google
                  </Button>
                  {/* <GoogleLogin auto_select width={"250px"} sx={{mt:5, width:"200px"}} display="block" onSuccess={login} onError={errorMessage} /> */}
                </Box>
                <AdBlockDetectedWrapper>
                  <Box
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center"
                    sx={{ mt: 4 }}
                    className="target"
                  >
                    <Alert
                      sx={{
                        mt: 5,
                        width: "100%",
                        textAlign: "center",
                        display: "block",
                      }}
                      icon={false}
                      mt={6}
                      variant="outlined"
                      severity="info"
                    >
                      Please disable AdBlock to login
                    </Alert>
                  </Box>
                </AdBlockDetectedWrapper>
              </>

              // <button onClick={login}>Sign in with Google 🚀 </button>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
export default App;
