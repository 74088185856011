import {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  RefAttributes,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  SetStateAction,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
// import isWeekend from 'date-fns/isWeekend';
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import EmailIcon from "@mui/icons-material/Email";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import {
  DatePicker,
  DatePickerToolbarProps,
} from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DatePickerToolbar } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";

import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

import OutlinedInput from "@mui/material/OutlinedInput";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// import SalesLoftIcon from '../assets/icons/salesloftlogo.svg';
import { ReactComponent as SalesLoftIcon } from "../assets/icons/salesloftlogo.svg";
import { ReactComponent as HubSpotIcon } from "../assets/icons/hubspotlogo.svg";
import { ReactComponent as SalesForceIcon } from "../assets/icons/salesforcelogo.svg";
import { Container, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import { JSX } from "react/jsx-runtime";

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function disableScroll() {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: '#3b3b3b',
    //   color: 'white',
    fontWeight: "bold",
  },
  // [`&.${tableCellClasses.body}`]: {
  fontSize: 14,
  maxWidth: 200,
  width: 200,
  minWidth: 200,
  whiteSpace: "nowrap",
  [`&.expandedDetails`]: {
    paddingLeft: 56,
  },
  [`&.checkBoxleft`]: {
    fontSize: 12,
    maxWidth: 40,
    width: 40,
    minWidth: 40,
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  [`&.leadName`]: {
    maxWidth: 300,
    width: 300,
    minWidth: 300,
  },
  [`&.salesLoft`]: {
    maxWidth: 175,
    width: 175,
    minWidth: 175,
  },
  [`&.iconsExternal`]: {
    maxWidth: 170,
    width: 170,
    minWidth: 170,
  },
  [`&.interesetChip`]: {
    maxWidth: 160,
    width: 160,
    minWidth: 160,
  },
  [`&.ownerCol`]: {
    maxWidth: 150,
    width: 150,
    minWidth: 150,
  },
  [`&.interestCol`]: {
    maxWidth: 140,
    width: 140,
    minWidth: 140,
  },
  // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.noBorder > *`]: {
    borderBottom: "unset",
  },
}));

function CustomToolbarDate(
  props: JSX.IntrinsicAttributes &
    DatePickerToolbarProps<unknown> &
    RefAttributes<HTMLDivElement>
) {
  return (
    <Box
      // Pass the className to the root element to get correct layout
      className={props.className}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <DatePickerToolbar {...props} />
    </Box>
  );
}

dayjs.extend(isBetweenPlugin);

const lastMonday = dayjs().startOf("week");
const nextSunday = dayjs().endOf("week").startOf("day");
const isWeekend = (date: { day: () => any }) => {
  const day = date.day();

  return day === 0 || day === 6;
};

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<CustomPickerDayProps>(({ theme, isSelected, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const isInSameWeek = (
  dayA: dayjs.Dayjs,
  dayB: dayjs.Dayjs | null | undefined
) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
  }
) {
  const { day, selectedDay, ...other } = props;

  return (
    <CustomPickersDay
      {...(other as any)}
      day={day as any}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false as boolean}
      isSelected={isInSameWeek(day, selectedDay) as any}
    />
  );
}

function Row(
  idx: { row: any; interestLevel: any; ownersList: any },
  user: any
) {
  const userN = idx.row;
  // console.log(userN)
  const interestLevel = idx.interestLevel;
  const ownersList = idx.ownersList;
  const [open, setOpen] = useState(false);
  var userJson = localStorage.getItem("user");
  var loggedInUser = JSON.parse(userJson || "");

  var allowed = ["raa@raconteur.net"];
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dateOptionsShort: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  var classNamesSalesLoft = ["overflowEllipsis", "salesLoft"];
  const theme = useTheme();
  const secondarycol = "#fd2341";
  const [isUpdated, setIsUpdated] = useState(false);
  const [notOwnerUpdated, setNotOwnerUpdated] = useState(true);

  const [updating, setUpdating] = useState(false);
  const [buttonIsUpdatedText, setIsUpdatedText] = useState("Update interest");
  const [openUpdated, setOpenUpdate] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);
  const [interest, setInterest] = useState("");
  const [newOwner, setNewOwner] = useState("");

  const handleChangeInterest = (event: { target: { value: any } }) => {
    setInterest(event.target.value || "");
  };

  const handleChangeUpdateOwner = (event: { target: { value: any } }) => {
    setNewOwner(event.target.value || "");
  };

  const handleClickOpenDisqualify = () => {
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleClickChangeOwner = () => {
    setOpenOwner(true);
  };

  const handleCloseChangeOwner = () => {
    setOpenOwner(false);
  };

  var splittedMarketingCamp = [];

  const date = new Date();
  date.setDate(date.getDate() + 7);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const date7Days = `${year}-${month}-${day}`;

  var superAdmins = [
    "ra@raconteur.net",
    "klr@raconteur.net",
    "wb@raconteur.net",
    "rc@raconteur.net",
    "dk@raconteur.net",
    "jt@raconteur.net",
    "sj@raconteur.net",
    "jo@raconteur.net",
  ];

  var superAdmin = false;
  if (superAdmins.includes(loggedInUser.email)) {
    var superAdmin = true;
  }

  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);
  const [interestLaterDate, setInterestLaterDate] = useState(dayjs(date7Days));

  const updateInterest =
    (
      userID: string,
      interest: string,
      interestdate: string | number | Date | dayjs.Dayjs | null | undefined
    ) =>
    (event: any) => {
      setUpdating(true);

      const date = new Date();
      var interestdateunix = dayjs(interestdate).valueOf();

      fetch(
        ".netlify/functions/interest?user=" +
          userID +
          "&interest=" +
          interest +
          "&interestdate=" +
          interestdate,
        {
          method: "GET",
        }
      ).then((res) => {
        setIsUpdatedText("Updated");
        setUpdating(false);
        setIsUpdated(true);
        setOpenUpdate(false);
      });
    };

  const updateOwner = (userID: string, owner: string) => () => {
    fetch(".netlify/functions/owner?user=" + userID + "&owner=" + owner, {
      method: "GET",
    }).then((res) => {
      setOpenOwner(false);
      setNotOwnerUpdated(false);
    });
  };

  return (
    <>
      <StyledTableRow
        style={
          userN.notes_last_updated
            ? { color: "#e4e4e4", borderBottom: "unset" }
            : userN.owner == loggedInUser.email
            ? { color: "#eeffee", borderBottom: "unset" }
            : "dk@raconteur.net" == userN.owner
            ? { color: "#eeffee", borderBottom: "unset" }
            : {}
        }
        className="noBorder"
        hover={true}
      >
        <StyledTableCell className="checkBoxleft">
          <Tooltip title={open ? "Close" : "More details"}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon color="primary" />
              ) : (
                <KeyboardArrowDownIcon color="primary" />
              )}
            </IconButton>
          </Tooltip>
        </StyledTableCell>

        <StyledTableCell component="th" scope="row" className="leadName">
          <Typography
            variant="body2"
            display="block"
            fontWeight="fontWeightBold"
          >
            {userN.firstname} {userN.lastname}
          </Typography>

          <Tooltip title={userN.job_title + " @ " + userN.company}>
            <Typography variant="caption" display="block">
              {userN.job_title} @ {userN.company}
            </Typography>
          </Tooltip>

          <Typography variant="caption" display="block" fontSize={10}>
            {userN.email}
          </Typography>
        </StyledTableCell>

        <StyledTableCell className={classNamesSalesLoft.join(" ")}>
          {userN.notes_last_updated ? (
            <>
              {Math.floor(new Date(userN.notes_last_updated).getTime()) <
                Math.floor(new Date(userN.recent_conversion_date).getTime()) ||
              userN.contact_again == "true" ? (
                <>
                  <Tooltip title="Contacted before last conversion">
                    <Button
                      variant="contained"
                      target="_blank"
                      href={
                        "https://raconteur.lightning.force.com/lightning/r/Contact/" +
                        userN.salesforcecontactid +
                        "/view"
                      }
                      style={{
                        textTransform: "none",
                        padding: "4px 8px",
                        fontSize: "12px",
                      }}
                    >
                      Open in Salesforce
                    </Button>
                  </Tooltip>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 10, mt: 0.2 }}
                    color="warning.main"
                    variant="caption"
                    display="block"
                  >
                    {userN.interest_level_updated &&
                    Math.floor(new Date(userN.notes_last_updated).getTime()) <
                      Math.floor(
                        new Date(userN.interest_level_updated).getTime()
                      ) ? (
                      <>
                        {new Date(
                          userN.interest_level_updated
                        ).toLocaleDateString("en-UK", dateOptions)}
                      </>
                    ) : (
                      <>
                        {new Date(userN.notes_last_updated).toLocaleDateString(
                          "en-UK",
                          dateOptions
                        )}
                      </>
                    )}
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{ fontWeight: "bold" }}
                  color="primary.light"
                  variant="caption"
                  display="block"
                >
                  {new Date(userN.notes_last_updated).toLocaleDateString(
                    "en-UK",
                    dateOptions
                  )}
                </Typography>
              )}
            </>
          ) : (
            <>
              {userN.contact_again ? (
                <>
                  <Tooltip title="Contacted before last conversion">
                    <Button
                      variant="contained"
                      target="_blank"
                      href={
                        "https://raconteur.lightning.force.com/lightning/r/Contact/" +
                        userN.salesforcecontactid +
                        "/view"
                      }
                      style={{
                        textTransform: "none",
                        padding: "4px 8px",
                        fontSize: "12px",
                      }}
                    >
                      Open in Salesforce
                    </Button>
                  </Tooltip>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 10, mt: 0.2 }}
                    color="warning.main"
                    variant="caption"
                    display="block"
                  >
                    {new Date(userN.notes_last_updated).toLocaleDateString(
                      "en-UK",
                      dateOptions
                    )}
                  </Typography>
                </>
              ) : (
                <Tooltip title="Not yet contacted">
                  <Button
                    variant="contained"
                    target="_blank"
                    href={
                      "https://raconteur.lightning.force.com/lightning/r/Contact/" +
                      userN.salesforcecontactid +
                      "/view"
                    }
                    style={{
                      textTransform: "none",
                      padding: "4px 8px",
                      fontSize: "12px",
                    }}
                  >
                    Open in Salesforce
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </StyledTableCell>

        <StyledTableCell className="overflowPre">
          <Tooltip
            sx={{ fontWeight: "bold" }}
            title={userN.recent_conversion_event_name}
          >
            <Typography variant="caption" display="block">
              {userN.recent_conversion_event_name}
            </Typography>
          </Tooltip>
          {userN.recent_conversion_date && (
            <Typography variant="caption" display="block">
              {new Date(userN.recent_conversion_date).toLocaleDateString(
                "en-UK",
                dateOptions
              )}
            </Typography>
          )}
        </StyledTableCell>

        <StyledTableCell className="interestCol">
          <Tooltip title={!isUpdated ? "Click to update" : "Updated"}>
            <Button
              disabled={!isUpdated ? false : true}
              style={{
                textTransform: "none",
                padding: "4px 8px",
                fontSize: "12px",
              }}
              variant="outlined"
              color="primary"
              onClick={handleClickOpenDisqualify}
            >
              {buttonIsUpdatedText}
            </Button>
          </Tooltip>
          <Dialog
            open={openUpdated}
            onClose={handleCloseUpdate}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you want to update this contact?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                By clicking update, you are going to update the interest for
              </DialogContentText>
              <Typography variant="h6" display="block" fontWeight={600}>
                {userN.firstname} {userN.lastname}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl sx={{ mt: 2, width: "100%" }}>
                  <InputLabel htmlFor="demo-dialog-native">Interest</InputLabel>
                  <Select
                    value={interest}
                    onChange={handleChangeInterest}
                    input={
                      <OutlinedInput label="Interest" id="demo-dialog-native" />
                    }
                  >
                    <option aria-label="None" value="" />
                    {interestLevel &&
                      interestLevel.map(
                        (interestLevelSingle: any, idx: any) => (
                          <MenuItem
                            key={interestLevelSingle}
                            value={interestLevelSingle}
                          >
                            {interestLevelSingle}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  {interest == "Maybe Interested Later" && (
                    <>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        mt={2}
                        align="center"
                      >
                        Select a date for the future interest
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          sx={{ background: "transparent" }}
                          openTo="day"
                          disablePast={true}
                          // displayStaticWrapperAs="desktop"
                          disableHighlightToday={true}
                          showDaysOutsideCurrentMonth
                          shouldDisableDate={isWeekend}
                          value={dayjs(interestLaterDate)}
                          onChange={(newValue) => {
                            setInterestLaterDate(newValue);
                          }}
                          slots={{ day: Day }}
                          slotProps={{
                            day: (ownerState) =>
                              ({
                                selectedDay: interestLaterDate,

                                // hoveredDay,
                                // onPointerEnter: () => setHoveredDay(ownerState.day),
                                // onPointerLeave: () => setHoveredDay(null),
                              } as any),
                          }}
                        />
                      </LocalizationProvider>
                    </>
                  )}
                </FormControl>
              </Box>

              <DialogContentText
                display="block"
                fontWeight="bold"
                id="alert-dialog-description"
              >
                <br />
                Do you want to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUpdate}>Cancel</Button>
              <Button
                disabled={interest != "" ? false : true}
                variant="contained"
                color="primary"
                onClick={updateInterest(userN.id, interest, interestLaterDate)}
                autoFocus
              >
                Update interest
              </Button>
            </DialogActions>
          </Dialog>
        </StyledTableCell>

        <StyledTableCell className="interesetChip">
          {userN.disqualified_date_sf ? (
            <>
              <Tooltip
                sx={{ fontWeight: "bold" }}
                title={"Disqualified / Not interested"}
              >
                <Chip
                  label={"DISQUALIFIED"}
                  size="small"
                  sx={{
                    textTransform: "uppercase",
                    width: "100%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
              </Tooltip>
              <Typography
                variant="caption"
                mt="0.1"
                display="block"
                sx={{ fontSize: 10, textAlign: "center" }}
              >
                {new Date(userN.disqualified_date_sf).toLocaleDateString(
                  "en-UK",
                  dateOptionsShort
                )}
              </Typography>
            </>
          ) : userN.interest_level_sf &&
            userN.interest_level_sf.toLowerCase().includes("disqualified") &&
            !userN.disqualified_date_sf ? (
            <Tooltip
              sx={{ fontWeight: "bold" }}
              title={userN.interest_level_sf}
            >
              <Chip
                label={"DISQUALIFIED"}
                size="small"
                sx={{
                  textTransform: "uppercase",
                  width: "100%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              />
            </Tooltip>
          ) : userN.interest_level_sf &&
            userN.interest_level_sf.toLowerCase().includes("maybe") &&
            userN.interested_later_date ? (
            <>
              <Tooltip
                sx={{ fontWeight: "bold" }}
                title={userN.interest_level_sf}
              >
                <Chip
                  size="small"
                  sx={{
                    textTransform: "uppercase",
                    width: "100%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                  label={userN.interest_level_sf}
                />
              </Tooltip>
              <Tooltip title={"Interest later date"}>
                <Typography
                  variant="caption"
                  mt="0.1"
                  display="block"
                  sx={{ fontSize: 10, textAlign: "center" }}
                >
                  {new Date(userN.interested_later_date).toLocaleDateString(
                    "en-UK",
                    dateOptionsShort
                  )}
                </Typography>
              </Tooltip>
            </>
          ) : userN.interest_level_sf &&
            !userN.interest_level_sf.toLowerCase().includes("disqualified") &&
            userN.interest_level_updated ? (
            <>
              <Tooltip
                sx={{ fontWeight: "bold" }}
                title={userN.interest_level_sf}
              >
                <Chip
                  size="small"
                  sx={{
                    textTransform: "uppercase",
                    width: "100%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                  label={userN.interest_level_sf}
                />
              </Tooltip>
              <Tooltip title={"Date of last update"}>
                <Typography
                  variant="caption"
                  mt="0.1"
                  display="block"
                  sx={{ fontSize: 10, textAlign: "center" }}
                >
                  {new Date(userN.interest_level_updated).toLocaleDateString(
                    "en-UK",
                    dateOptionsShort
                  )}
                </Typography>
              </Tooltip>
            </>
          ) : userN.interest_level_sf &&
            !userN.interest_level_sf.toLowerCase().includes("disqualified") ? (
            <Tooltip
              sx={{ fontWeight: "bold" }}
              title={userN.interest_level_sf}
            >
              <Chip
                size="small"
                sx={{
                  textTransform: "uppercase",
                  width: "100%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                label={userN.interest_level_sf}
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </StyledTableCell>

        <StyledTableCell className="overflowPre">
          {userN.engaged_marketing_campaigns &&
            ((splittedMarketingCamp =
              userN.engaged_marketing_campaigns.split(";")),
            splittedMarketingCamp.map(
              (
                interestLevelSingle:
                  | string
                  | number
                  | boolean
                  | ReactElement<any, string | JSXElementConstructor<any>>
                  | Iterable<ReactNode>
                  | null
                  | undefined,
                idxSingleCamp: Key | null | undefined
              ) => (
                <Tooltip
                  key={idxSingleCamp}
                  sx={{ fontWeight: "bold" }}
                  title={interestLevelSingle}
                >
                  <Typography variant="caption" display="block">
                    {interestLevelSingle}
                  </Typography>
                </Tooltip>
              )
            ))}
        </StyledTableCell>

        <StyledTableCell className="overflowEllipsis">
          <Tooltip sx={{ fontWeight: "bold" }} title={userN.vertical_sf}>
            <Typography variant="caption" display="block">
              {userN.vertical_sf}
            </Typography>
          </Tooltip>
        </StyledTableCell>

        <StyledTableCell className="ownerCol">
          {superAdmin && notOwnerUpdated ? (
            <>
              <Tooltip title={"Click to change owner"}>
                <Box onClick={handleClickChangeOwner}>
                  <Typography
                    variant="caption"
                    display="block"
                    fontWeight="bold"
                  >
                    {userN.ownername || "Add owner"}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {userN.owner}
                  </Typography>
                </Box>
              </Tooltip>
              <Dialog
                open={openOwner}
                onClose={handleCloseChangeOwner}
                aria-labelledby="owner-dialog-title"
                aria-describedby="owner-dialog-description"
              >
                <DialogTitle id="owner-dialog-title">
                  {"Do you want to update this contact?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="owner-dialog-description">
                    By clicking update, you are going to update the owner for
                  </DialogContentText>
                  <Typography variant="h6" display="block" fontWeight={600}>
                    {userN.firstname} {userN.lastname}
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <FormControl sx={{ mt: 2, width: "100%" }}>
                      <InputLabel htmlFor="owner-dialog-native">
                        New owner
                      </InputLabel>
                      <Select
                        value={newOwner}
                        onChange={handleChangeUpdateOwner}
                        input={
                          <OutlinedInput
                            label="New owner"
                            id="owner-dialog-native"
                          />
                        }
                      >
                        {ownersList &&
                          ownersList.map(
                            (
                              ownersListSingle: {
                                id: string | number;
                                name: string;
                              },
                              idx: Key
                            ) => (
                              <MenuItem key={idx} value={ownersListSingle.id}>
                                {ownersListSingle.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Box>

                  <DialogContentText
                    display="block"
                    fontWeight="bold"
                    id="owner-dialog-description"
                  >
                    <br />
                    Do you want to continue?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseChangeOwner}>Cancel</Button>
                  <Button
                    disabled={newOwner != "" ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={updateOwner(userN.id, newOwner)}
                    autoFocus
                  >
                    Update owner
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : superAdmin && !notOwnerUpdated ? (
            <>
              <Button
                disabled={true}
                style={{
                  textTransform: "none",
                  padding: "4px 8px",
                  fontSize: "12px",
                }}
                variant="outlined"
                color="primary"
              >
                Updated
              </Button>
            </>
          ) : superAdmin && userN.ownername == "" ? (
            <>
              <Tooltip title={"Click to change owner"}>
                <Box onClick={handleClickChangeOwner}>
                  <Typography
                    variant="caption"
                    display="block"
                    fontWeight="bold"
                  >
                    Add owner
                  </Typography>
                </Box>
              </Tooltip>
              <Dialog
                open={openOwner}
                onClose={handleCloseChangeOwner}
                aria-labelledby="owner-dialog-title"
                aria-describedby="owner-dialog-description"
              >
                <DialogTitle id="owner-dialog-title">
                  {"Do you want to update this contact?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="owner-dialog-description">
                    By clicking update, you are going to update the owner for
                  </DialogContentText>
                  <Typography variant="h6" display="block" fontWeight={600}>
                    {userN.firstname} {userN.lastname}
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <FormControl sx={{ mt: 2, width: "100%" }}>
                      <InputLabel htmlFor="owner-dialog-native">
                        New owner
                      </InputLabel>
                      <Select
                        value={newOwner}
                        onChange={handleChangeUpdateOwner}
                        input={
                          <OutlinedInput
                            label="New owner"
                            id="owner-dialog-native"
                          />
                        }
                      >
                        {ownersList &&
                          ownersList.map(
                            (
                              ownersListSingle: {
                                id: string | number;
                                name: string;
                              },
                              idx: Key
                            ) => (
                              <MenuItem key={idx} value={ownersListSingle.id}>
                                {ownersListSingle.name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Box>

                  <DialogContentText
                    display="block"
                    fontWeight="bold"
                    id="owner-dialog-description"
                  >
                    <br />
                    Do you want to continue?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseChangeOwner}>Cancel</Button>
                  <Button
                    disabled={newOwner != "" ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={updateOwner(userN.id, newOwner)}
                    autoFocus
                  >
                    Update owner
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <Typography variant="caption" display="block" fontWeight="bold">
                {userN.ownername}
              </Typography>
              <Typography variant="caption" display="block">
                {userN.owner}
              </Typography>
            </>
          )}
        </StyledTableCell>

        <StyledTableCell className="iconsExternal">
          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            justifyContent="start"
            alignContent="start"
          >
            <Tooltip title="Salesforce">
              <IconButton
                className="changeIconMode"
                target="_blank"
                href={
                  "https://raconteur.lightning.force.com/lightning/r/Contact/" +
                  userN.salesforcecontactid +
                  "/view"
                }
                aria-label="Salesforce"
                color="primary"
                sx={{ width: 50, height: 50, mr: 0.1, ml: -1 }}
              >
                <SvgIcon
                  color="primary"
                  component={SalesForceIcon}
                  inheritViewBox
                  sx={{ width: 40 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Salesloft">
              <IconButton
                className="changeIconMode"
                target="_blank"
                href={
                  "https://app.salesloft.com/app/people?query=" +
                  encodeURIComponent(userN.email) +
                  "&selectedOwnerOption=all_including_inactive"
                }
                aria-label="Salesloft"
                color="primary"
                sx={{ width: 30, height: 30 }}
              >
                <SvgIcon
                  color="primary"
                  component={SalesLoftIcon}
                  inheritViewBox
                  sx={{ width: 13 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="HubSpot">
              <IconButton
                className="changeIconMode"
                target="_blank"
                href={
                  "https://app-eu1.hubspot.com/contacts/26058824/record/0-1/" +
                  encodeURIComponent(userN.id)
                }
                aria-label="HubSpot"
                color="primary"
                sx={{ width: 30, height: 30 }}
              >
                <SvgIcon
                  color="primary"
                  component={HubSpotIcon}
                  inheritViewBox
                  sx={{ width: 15 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Email">
              <IconButton
                className="changeIconMode"
                component="a"
                target="_blank"
                href={"mailto:" + userN.email}
                aria-label="Email"
                sx={{ width: 30, height: 30 }}
              >
                <EmailIcon sx={{ width: 18, color: "#ffffff" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <StyledTableCell
          className="expandedDetails"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={10}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 0, ml: 0, mr: 0, mb: 1 }}>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={3}
                  sx={{ borderRight: 1, borderColor: "#454a4f" }}
                >
                  <Box sx={{ mt: 1, ml: 0, mr: 2, mb: 1 }}>
                    <Typography
                      color="#454a4f"
                      variant="caption"
                      display="block"
                      fontWeight="bold"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Name
                    </Typography>
                    <Typography
                      variant="body1"
                      display="block"
                      fontWeight="bold"
                    >
                      {userN.firstname} {userN.lastname}
                    </Typography>
                    <Typography variant="caption" display="block" mb={1}>
                      {userN.email}
                    </Typography>
                    <Typography
                      pt={1}
                      sx={{ borderTop: 1, textTransform: "uppercase" }}
                      color="#454a4f"
                      variant="caption"
                      display="block"
                      fontWeight="bold"
                    >
                      Role @ Company
                    </Typography>
                    <Typography
                      variant="body2"
                      display="block"
                      fontWeight="bold"
                      mb={2}
                    >
                      {userN.job_title} @ {userN.company}
                    </Typography>
                    {userN.industry_hb && (
                      <Typography
                        mt={1}
                        pt={1}
                        sx={{ borderTop: 1 }}
                        color="#454a4f"
                        variant="caption"
                        display="block"
                      ></Typography>
                    )}
                    {userN.industry_hb && (
                      <>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Industry
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                        >
                          {userN.industry_hb}
                        </Typography>
                        {userN.industry_sf && (
                          <>
                            <Typography
                              variant="body2"
                              display="block"
                              fontWeight="bold"
                            >
                              {userN.industry_sf} (Salesforce)
                            </Typography>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ borderRight: 1, borderColor: "#454a4f" }}
                >
                  <Box sx={{ mt: 1, ml: 2, mr: 2, mb: 1 }}>
                    {userN.vertical_sf && (
                      <>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Vertical
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                          mb={1}
                        >
                          {userN.vertical_sf}
                        </Typography>
                      </>
                    )}
                    {userN.recent_conversion_date && userN.vertical_sf && (
                      <Typography
                        mt={1}
                        pt={1}
                        sx={{ borderTop: 1 }}
                        color="#454a4f"
                        variant="caption"
                        display="block"
                      ></Typography>
                    )}
                    {!userN.recent_conversion_date &&
                      userN.vertical_sf &&
                      userN.engaged_marketing_campaigns && (
                        <Typography
                          mt={1}
                          pt={1}
                          sx={{ borderTop: 1 }}
                          color="#454a4f"
                          variant="caption"
                          display="block"
                        ></Typography>
                      )}
                    {userN.recent_conversion_date && (
                      <>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Last conversion (activity)
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                        >
                          {userN.recent_conversion_event_name}
                        </Typography>
                        <Typography
                          mb={1}
                          mt={0.1}
                          variant="caption"
                          display="block"
                        >
                          {new Date(
                            userN.recent_conversion_date
                          ).toLocaleDateString("en-UK", dateOptions)}
                        </Typography>
                      </>
                    )}
                    {userN.recent_conversion_date &&
                      userN.engaged_marketing_campaigns && (
                        <Typography
                          mt={1}
                          pt={1}
                          sx={{ borderTop: 1 }}
                          color="#454a4f"
                          variant="caption"
                          display="block"
                        ></Typography>
                      )}

                    {userN.engaged_marketing_campaigns && (
                      <Box sx={{ pb: 2 }}>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Campaign engaged
                        </Typography>
                        {userN.engaged_marketing_campaigns &&
                          ((splittedMarketingCamp =
                            userN.engaged_marketing_campaigns.split(";")),
                          splittedMarketingCamp.map(
                            (
                              interestLevelSingle:
                                | string
                                | number
                                | boolean
                                | ReactElement<
                                    any,
                                    string | JSXElementConstructor<any>
                                  >
                                | Iterable<ReactNode>
                                | ReactPortal
                                | null
                                | undefined,
                              idxSingleCamp: Key | null | undefined
                            ) => (
                              <Typography
                                key={idxSingleCamp}
                                variant="body2"
                                display="block"
                                fontWeight="bold"
                              >
                                {interestLevelSingle}
                              </Typography>
                            )
                          ))}

                        {userN.engaged_marketing_campaigns
                          .toLowerCase()
                          .includes("webinar") &&
                          userN.livestorm_webinar_attended &&
                          userN.livestorm_webinar_attended == "TRUE" && (
                            <>
                              <Alert
                                icon={false}
                                variant="filled"
                                severity="success"
                                sx={{
                                  color: "white",
                                  mt: 1,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  pt: "1px",
                                  pb: "1px",
                                  pl: "16px",
                                  pr: "16px",
                                  width: 134,
                                }}
                              >
                                Webinar attended
                              </Alert>
                            </>
                          )}
                        {userN.engaged_marketing_campaigns
                          .toLowerCase()
                          .includes("webinar") &&
                          userN.livestorm_webinar_attended &&
                          userN.livestorm_webinar_attended == "FALSE" && (
                            <>
                              <Alert
                                icon={false}
                                variant="filled"
                                severity="info"
                                sx={{
                                  color: "white",
                                  mt: 1,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  pt: "1px",
                                  pb: "1px",
                                  pl: "16px",
                                  pr: "16px",
                                  width: 156,
                                }}
                              >
                                Webinar not attended
                              </Alert>
                            </>
                          )}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ borderRight: 1, borderColor: "#454a4f" }}
                >
                  <Box sx={{ mt: 1, ml: 2, mr: 2, mb: 1 }}>
                    {userN.hubspotscore && (
                      <>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          HubSpot Score
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                          mb={1}
                        >
                          {userN.hubspotscore}
                        </Typography>
                      </>
                    )}

                    {userN.hubspotscore && (
                      <Typography
                        mt={1}
                        pt={1}
                        sx={{ borderTop: 1 }}
                        color="#454a4f"
                        variant="caption"
                        display="block"
                      ></Typography>
                    )}

                    {userN.completed_inbound_actions && (
                      <Box sx={{ pb: 2 }}>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Completed Inbound
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                        >
                          {userN.completed_inbound_actions}
                        </Typography>
                        {userN.contact_us_message && (
                          <>
                            <Alert
                              icon={false}
                              variant="filled"
                              severity="info"
                              sx={{
                                color: "white",
                                width: "100%",
                                mt: 1,
                                fontSize: 12,
                                fontWeight: "bold",
                                pt: "1px",
                                pb: "1px",
                                pl: "16px",
                                pr: "16px",
                              }}
                            >
                              {userN.contact_us_message}
                            </Alert>
                          </>
                        )}
                      </Box>
                    )}

                    {userN.completed_inbound_actions && (
                      <Typography
                        mt={0}
                        pt={1}
                        sx={{ borderTop: 1 }}
                        color="#454a4f"
                        variant="caption"
                        display="block"
                      ></Typography>
                    )}

                    <Typography
                      color="#454a4f"
                      variant="caption"
                      display="block"
                      fontWeight="bold"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Original source
                    </Typography>
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      variant="body2"
                      display="block"
                      fontWeight="bold"
                      mb={2}
                    >
                      {userN.hs_analytics_source
                        .replace("_", " ")
                        .toLowerCase()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{ mt: 1, ml: 2, mr: 2, mb: 1 }}>
                    {userN.sales_campaign_dd && (
                      <Box sx={{ pb: 2 }}>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Last marketing campaign
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                        >
                          {userN.sales_campaign_dd}
                        </Typography>
                        <Typography
                          mb={0}
                          mt={0.1}
                          variant="caption"
                          display="block"
                        >
                          {new Date(
                            userN.date_of_last_marketing_campaign_sf
                          ).toLocaleDateString("en-UK", dateOptionsShort)}
                        </Typography>
                      </Box>
                    )}

                    {userN.sales_campaign_dd && (
                      <Typography
                        mt={0}
                        pt={1}
                        sx={{ borderTop: 1 }}
                        color="#454a4f"
                        variant="caption"
                        display="block"
                      ></Typography>
                    )}

                    {userN.dealstage && (
                      <Box sx={{ pb: 2 }}>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Deal details
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                        >
                          {userN.dealstage}
                        </Typography>
                      </Box>
                    )}

                    {userN.total_revenue && (
                      <Typography
                        mt={0}
                        pt={1}
                        sx={{ borderTop: 1 }}
                        color="#454a4f"
                        variant="caption"
                        display="block"
                      ></Typography>
                    )}

                    {userN.total_revenue && (
                      <Box sx={{ pb: 2 }}>
                        <Typography
                          color="#454a4f"
                          variant="caption"
                          display="block"
                          fontWeight="bold"
                          sx={{ textTransform: "uppercase" }}
                        >
                          Deal Amount
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          fontWeight="bold"
                        >
                          £
                          {parseFloat(userN.total_revenue).toLocaleString(
                            "en-UK"
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

const headCells = [
  {
    id: "lead",
    numeric: false,
    disablePadding: false,
    label: "Name",
    enableSort: false,
    width: "300",
    className: "leadName",
  },
  {
    id: "notes_last_updated",
    numeric: false,
    disablePadding: false,
    label: "Last contacted",
    enableSort: true,
    width: "175",
    className: "salesLoft",
  },

  {
    id: "recent_conversion_date",
    numeric: false,
    disablePadding: false,
    label: "Recent conversion",
    enableSort: true,
    width: "200",
    // className: "leadName"
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Interest level",
    enableSort: false,
    width: "140",
    className: "interestCol",
  },
  {
    id: "disqualified_date__sf_",
    numeric: false,
    disablePadding: false,
    label: "",
    enableSort: false,
    width: "160",
    className: "interesetChip",
  },

  {
    id: "proengaged_marketing_campaignstein",
    numeric: false,
    disablePadding: false,
    label: "Campaign engaged",
    enableSort: false,
    width: "200",
    //   className: "leadName"
  },
  {
    id: "vertical_sf",
    numeric: false,
    disablePadding: false,
    label: "Vertical",
    enableSort: false,
    width: "200",
    //   className: "leadName"
  },

  {
    id: "owner",
    numeric: false,
    disablePadding: false,
    label: "Owner",
    enableSort: false,
    width: "150",
    className: "ownerCol",
  },
  {
    id: "icons_external",
    numeric: false,
    disablePadding: false,
    label: "Links",
    align: "center",
    enableSort: false,
    width: "170",
    className: "iconsExternal",
  },
];

function EnhancedTableHead(props: {
  order: any;
  orderBy: any;
  rowCount: any;
  onRequestSort: any;
}) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell className="checkBoxleft" />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
            className={headCell.className}
          >
            {headCell.enableSort == true ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function TableS() {
  const targetRef = useRef();

  var [users, setUsers] = useState([]);

  var [nextPage, setNextPage] = useState("");
  var newa: any[];
  var [loading, setLoading] = useState(false);
  var [loadingBottom, setLoadingBottom] = useState(false);
  var [tooMany, setTooMany] = useState(false);
  const [campaignsEngaged, setCampaignsEngaged] = useState([]);
  const [campaignsEngagedFilter, setCampaignsEngagedFilter] = useState([]);
  const [campaignsEngagedFilterString, setCampaignsEngagedFilterString] =
    useState("");
  const [completedInbound, setCompletedInbound] = useState([]);
  const [completedInboundFilter, setCompletedInboundFilter] = useState([]);
  const [completedInboundFilterString, setCompletedInboundFilterString] =
    useState("");
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("recent_conversion_date");
  var [ownerSelection, setOwnerSelection] = useState(false);
  var [contactedSelection, setContactedSelection] = useState(false);
  var userJson = localStorage.getItem("user");
  var loggedInUser = JSON.parse(userJson as any);

  const [companySearch, setCompanySearch] = useState("");

  var loggedInUserEmail = encodeURIComponent(loggedInUser.email);
  var loggedInUserName = loggedInUser.name;
  var [interestLevel, setInterestLevel] = useState([]);

  const [ownerListFilter, setOwnerListFilter] = useState([loggedInUserName]);
  const [ownerListFilterString, setOwnerListFilterString] =
    useState("Personal");
  var [showArrows, setShowArrows] = useState(false);
  const handleChangeOwner = (event: any, property: any) => {
    if (property) {
      setLoadingBottom(true);
      setOwnerSelection(true);
      setOwnerListFilter(["Show all owners"]);
      setOwnerListFilterString("all");
      fetchMore(
        "0",
        [],
        orderBy,
        order,
        loggedInUserEmail,
        contactedSelection,
        campaignsEngagedFilterString,
        completedInboundFilterString,
        "all",
        companySearch
      );
    } else {
      setLoadingBottom(true);
      setOwnerSelection(false);
      var resultIDOwner = ownersList.find(
        ({ email }) => email === decodeURIComponent(loggedInUserEmail)
      );
      if (resultIDOwner) {
        resultIDOwner = resultIDOwner["id"];
      }
      setOwnerListFilter([loggedInUserName]);
      setOwnerListFilterString(resultIDOwner || "");
      fetchMore(
        "0",
        [],
        orderBy,
        order,
        loggedInUserEmail,
        contactedSelection,
        campaignsEngagedFilterString,
        completedInboundFilterString,
        resultIDOwner as any,
        companySearch
      );
    }
  };

  const handleCompanySearch = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const companySearchValue = (
      document.getElementById("CompanySearch") as HTMLInputElement
    ).value;
    // console.log(companySearch)
    setLoadingBottom(true);
    setCompanySearch(companySearchValue);
    fetchMore(
      "0",
      [],
      orderBy,
      order,
      loggedInUserEmail,
      contactedSelection,
      campaignsEngagedFilterString,
      completedInboundFilterString,
      ownerListFilterString,
      companySearchValue
    );
  };

  const handleChangeContacted = (event: any, property: any) => {
    if (property) {
      setLoadingBottom(true);
      setContactedSelection(true);
      fetchMore(
        "0",
        [],
        orderBy,
        order,
        loggedInUserEmail,
        true,
        campaignsEngagedFilterString,
        completedInboundFilterString,
        ownerListFilterString,
        companySearch
      );
    } else {
      setLoadingBottom(true);
      setContactedSelection(false);
      fetchMore(
        "0",
        [],
        orderBy,
        order,
        loggedInUserEmail,
        false,
        campaignsEngagedFilterString,
        completedInboundFilterString,
        ownerListFilterString,
        companySearch
      );
    }
  };

  const handleChangeCompletedInbound = (event: { target: { value: any } }) => {
    setLoadingBottom(true);
    const {
      target: { value },
    } = event;

    var encodedValue = "";
    value.forEach((singleValue: string | number | boolean) => {
      encodedValue = encodedValue + encodeURIComponent(singleValue) + ",";
    });
    encodedValue = encodedValue.replace(/,\s*$/, "");
    setCompletedInboundFilterString(encodedValue);
    setCompletedInboundFilter(value);
    fetchMore(
      "0",
      [],
      orderBy,
      order,
      loggedInUserEmail,
      contactedSelection,
      campaignsEngagedFilterString,
      encodedValue,
      ownerListFilterString,
      companySearch
    );
  };

  const handleChangeFilterEngaged = (event: { target: { value: any } }) => {
    setLoadingBottom(true);
    const {
      target: { value },
    } = event;

    var encodedValue = "";
    value.forEach((singleValue: string | number | boolean) => {
      encodedValue = encodedValue + encodeURIComponent(singleValue) + ",";
    });
    encodedValue = encodedValue.replace(/,\s*$/, "");
    setCampaignsEngagedFilterString(encodedValue);
    setCampaignsEngagedFilter(value);

    fetchMore(
      "0",
      [],
      orderBy,
      order,
      loggedInUserEmail,
      contactedSelection,
      encodedValue,
      completedInboundFilterString,
      ownerListFilterString,
      companySearch
    );

    // setCampaignsEngagedFilter(value);
  };

  const handleChangeOwnersList = (
    event: { target: { value: any } },
    valueNew: { props: { value: string } }
  ) => {
    setLoadingBottom(true);
    var {
      target: { value },
    } = event;

    // console.log(valueNew.props.value)
    var encodedValue = "";
    if (valueNew.props.value == "all") {
      value = ["Show all owners"];
      encodedValue = "all";
      setOwnerSelection(true);
    } else if (valueNew.props.value == "partial") {
      value = ["Show contacts without owners"];
      encodedValue = "partial";
      setOwnerSelection(false);
    } else if (valueNew.props.value == "Personal") {
      value = [loggedInUserName];
      encodedValue = "";
      setOwnerSelection(false);
      var resultIDOwner = ownersList.find(
        ({ email }) => email === decodeURIComponent(loggedInUserEmail)
      );
      if (resultIDOwner) {
        encodedValue = resultIDOwner["id"];
      }
      // console.log(encodedValue)
      // loggedInUserEmail
    } else {
      setOwnerSelection(false);
      const resultID = ownersList.find(
        ({ name }) => name === valueNew.props.value
      );
      if (resultID) {
        value = [valueNew.props.value];
        encodedValue = resultID["id"];
      }
    }

    encodedValue = encodedValue.replace(/,\s*$/, "");
    setOwnerListFilterString(encodedValue);
    setOwnerListFilter(value);

    fetchMore(
      "0",
      [],
      orderBy,
      order,
      loggedInUserEmail,
      contactedSelection,
      campaignsEngagedFilterString,
      completedInboundFilterString,
      encodedValue,
      companySearch
    );

    // setCampaignsEngagedFilter(value);
  };

  const handleRequestSort = (event: any, property: string) => {
    setLoadingBottom(true);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchMore(
      "0",
      [],
      property,
      isAsc ? "desc" : "asc",
      loggedInUserEmail,
      contactedSelection,
      campaignsEngagedFilterString,
      completedInboundFilterString,
      ownerListFilterString,
      companySearch
    );

    // console.log(property);
  };

  const loadMoreOnClick = () => {
    setLoadingBottom(true);
    loadingExtra = true;
    fetchMore(
      nextPage,
      users,
      orderBy,
      order,
      loggedInUserEmail,
      contactedSelection,
      campaignsEngagedFilterString,
      completedInboundFilterString,
      ownerListFilterString,
      companySearch
    );
  };

  var [scrollLeftDisable, setScrollLeftDisable] = useState(true);
  var [scrollRightDisable, setScrollRightDisable] = useState(false);

  const scrollLeft = () => {
    // const leadsTableContainer = document.getElementById('leadsTableContainer');
    const leadsTableContainer = document.getElementById(
      "leadsTableContainer"
    ) as HTMLInputElement;
    var scrollLeft = leadsTableContainer.scrollLeft - 500;
    if (leadsTableContainer?.scrollLeft - 500 <= 0) {
      setScrollLeftDisable(true);
      setScrollRightDisable(false);
    } else {
      setScrollLeftDisable(false);
    }
    leadsTableContainer.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    // const leadsTableContainer = document.getElementById('leadsTableContainer');
    const leadsTableContainer = document.getElementById(
      "leadsTableContainer"
    ) as HTMLInputElement;
    var scrollLeft = leadsTableContainer.scrollLeft + 500;

    if (
      leadsTableContainer?.scrollLeft +
      leadsTableContainer?.clientWidth +
      500
    ) {
      setScrollRightDisable(true);
      setScrollLeftDisable(false);
    } else {
      setScrollRightDisable(false);
    }

    leadsTableContainer.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  };

  const functionArrows = (user: string[]) => {
    if (user && user[0] != "toomany") {
      setShowArrows(true);
    } else {
      setShowArrows(false);
    }
  };

  const functionArrowsLeave = () => {
    setShowArrows(false);
  };

  function fetchMore(
    offset: string,
    users: any[],
    column: string,
    ordercolumn: string,
    owner: string,
    contacted: boolean,
    campaign: string,
    completed: string,
    ownerlist: string,
    company: string
  ) {
    setLoadingBottom(true);
    fetch(
      ".netlify/functions/leadstable?offset=" +
        offset +
        "&column=" +
        column +
        "&order=" +
        ordercolumn +
        "&owner=" +
        encodeURIComponent(owner) +
        "&contacted=" +
        contacted +
        "&campaign=" +
        campaign +
        "&completed=" +
        completed +
        "&ownerlist=" +
        ownerlist +
        "&company=" +
        company,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((json) =>
        json[0] == "toomany"
          ? setTooMany(true)
          : (setTooMany(false),
            (newa = [...users, ...json]),
            setUsers(newa as any))
      )

      .finally(() => {
        if (newa) {
          if (newa[newa.length - 1] && newa[newa.length - 1]["nextpage"]) {
            // console.log(newa[0]['nextpage'])
            setNextPage(newa[newa.length - 1]["nextpage"]);
          } else {
            setNextPage("");
          }

          // window.scrollTo(0, document.body.scrollHeight - 400)

          setLoadingBottom(false);
          loadingExtra = false;
        } else {
          setUsers([]);
          setLoadingBottom(false);
          loadingExtra = false;
        }
      });
  }

  var filtersValues = [];
  var sortedArrayowners = [];
  var loadingExtra = false;

  useEffect(() => {
    if (loadingBottom) {
      disableScroll();
    } else {
      enableScroll();
    }

    const handleWindowWheel = (event: WheelEvent) => {
      if (loadingBottom) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWindowWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWindowWheel);
    };
  }, [loadingBottom]);

  useEffect(() => {
    setLoading(true);
    setLoadingBottom(true);

    fetch(".netlify/functions/leadsfilter", {
      method: "GET",
    })
      .then((response) => response.json())
      .then(
        (json) => (
          // console.log(json),
          (filtersValues = json),
          (sortedArrayowners = json["owners"].sort(function (
            a: { name: string },
            b: { name: string }
          ) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();

            return textA.localeCompare(textB);
          })),
          setOwnersList(sortedArrayowners),
          setCampaignsEngaged(json["engaged_marketing_campaigns"]),
          setCompletedInbound(json["completed_inbound_actions"]),
          setInterestLevel(json["interest_level_sf"])
        )
      );

    fetch(
      ".netlify/functions/leadstable?offset=0&owner=" +
        encodeURIComponent(loggedInUserEmail) +
        "&ownerslection=" +
        ownerSelection +
        "&ownerlist=Personal",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((json) => (setUsers(json), (newa = json)))
      .finally(() => {
        if (newa[0] && newa[0]["nextpage"]) {
          setNextPage(newa[0]["nextpage"]);
        }

        setLoading(false);

        setLoadingBottom(false);
      });
  }, []);
  // var isItemSelected;

  var [interestLevel, setInterestLevel] = useState([]);
  var [ownersList, setOwnersList] = useState([]);
  return (
    // <div ref={targetRef} className="App" style={{ marginTop: '20px' }}>
    <div className="App" style={{ marginTop: "20px" }}>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Divider
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontSize: 12,
              textTransform: "uppercase",
            }}
            textAlign="center"
          >
            Filter by
          </Divider>

          <Grid container spacing={1} alignItems="center" mb={1}>
            <Grid item xs={12}>
              <form
                className="form"
                style={{ padding: 0 }}
                onSubmit={handleCompanySearch}
              >
                <TextField
                  // value={companySearch}
                  // onChange={ e=>setCompanySearch(e.target.value)}
                  // display="block"
                  onSubmit={handleCompanySearch}
                  // size="small"
                  id="CompanySearch"
                  label="Search by name or company"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        id="searchIcon"
                        onClick={handleCompanySearch}
                        aria-label="search"
                        size="small"
                      >
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </form>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="campaign-engaged-chip-label">
                  Campaign engaged
                </InputLabel>
                <Select
                  labelId="campaign-engagede-chip-label"
                  id="campaign-engaged-chip"
                  multiple
                  value={campaignsEngagedFilter}
                  onChange={handleChangeFilterEngaged}
                  input={
                    <OutlinedInput
                      id="campaign-engaged-chip-select"
                      label="Campaign engaged"
                    />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        margin: "-2px 0 -8px",
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {campaignsEngaged &&
                    campaignsEngaged.map((campaignsEngagedSingle, idx) => (
                      <MenuItem
                        key={campaignsEngagedSingle}
                        value={campaignsEngagedSingle}
                      >
                        {campaignsEngagedSingle}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="completed-inboubnd-chip-label">
                  Completed Inbound
                </InputLabel>
                <Select
                  labelId="completed-inboubnd-chip-label"
                  id="completed-inboubnd-chip"
                  multiple
                  value={completedInboundFilter}
                  onChange={handleChangeCompletedInbound}
                  input={
                    <OutlinedInput
                      id="completed-inboubnd-chip-select"
                      label="Completed Inbound"
                    />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        margin: "-2px 0 -8px",
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {completedInbound &&
                    completedInbound.map((completedInboundSingle, idx) => (
                      <MenuItem
                        key={completedInboundSingle}
                        value={completedInboundSingle}
                      >
                        {completedInboundSingle}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="campaign-engaged-chip-label">Owner</InputLabel>
                <Select
                  labelId="owners-chip-label"
                  id="owners-chip"
                  // disabled={ownerSelection}
                  multiple
                  value={ownerListFilter}
                  onChange={handleChangeOwnersList as any}
                  input={
                    <OutlinedInput id="owners-chip-select" label="Owner" />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        margin: "-2px 0 -8px",
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Personal"}>{loggedInUserName}</MenuItem>
                  <MenuItem value={"all"}>Show all owners</MenuItem>
                  <MenuItem value={"partial"}>
                    Show contacts without owners
                  </MenuItem>
                  {ownersList &&
                    ownersList.map(
                      (
                        ownersListSingle: {
                          id: string | number;
                          name: string;
                          email: string;
                        },
                        idx: Key
                      ) =>
                        ownersListSingle.email !=
                          decodeURIComponent(loggedInUserEmail) && (
                          <MenuItem key={idx} value={ownersListSingle.name}>
                            {ownersListSingle.name}
                          </MenuItem>
                        )
                    )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <FormGroup>
                <Typography
                  variant="caption"
                  display="flex"
                  alignItems="center"
                  fontWeight="bold"
                >
                  <Switch
                    // checked={checked}
                    onChange={handleChangeContacted}
                    size="small"
                    inputProps={{ "aria-label": "Hide already contacted" }}
                  />
                  {/* {contactedSelection ? "Already " : "Hide already contacted"} */}
                  Hide already contacted and disqualified
                </Typography>
              </FormGroup>
            </Grid>

            <Grid item xs={4}>
              <FormGroup>
                <Typography
                  variant="caption"
                  display="flex"
                  alignItems="center"
                  fontWeight="bold"
                >
                  <Switch
                    checked={ownerSelection}
                    onChange={handleChangeOwner}
                    size="small"
                    inputProps={{ "aria-label": "Show all owners" }}
                  />
                  {/* {ownerSelection ? "Showing all contacts" : "Show all contacts"} */}
                  Show all contacts
                </Typography>
              </FormGroup>
            </Grid>
          </Grid>

          <div
            style={{ position: "relative" }}
            onMouseEnter={functionArrows as any}
            onMouseLeave={functionArrowsLeave as any}
          >
            <TableContainer component={Paper} id="leadsTableContainer">
              <Table
                className="sort-table"
                stickyHeader
                size="small"
                aria-labelledby="leadsList"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={users.length}
                />

                <TableBody className={loadingBottom ? "loadingTable" : ""}>
                  {users &&
                    users.map(
                      (user: any, idxRow) =>
                        user != "toomany" &&
                        !user.email?.includes("@raconteur.net") && (
                          <Row
                            key={"99" + idxRow}
                            row={user}
                            interestLevel={interestLevel}
                            ownersList={ownersList}
                          />
                        )
                      // : <></>
                    )}
                </TableBody>
              </Table>
            </TableContainer>

            {!tooMany && !loadingBottom && users && nextPage != "" && (
              <Grid item xs={12} id="bottomArrows">
                <Container maxWidth="xl">
                  <Tooltip title={"Scroll table left"}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={scrollLeft}
                      endIcon={
                        <KeyboardDoubleArrowLeftIcon sx={{ ml: 0, mr: 0 }} />
                      }
                    ></Button>
                  </Tooltip>

                  <Tooltip title={"Scroll table right"}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={scrollRight}
                      endIcon={
                        <KeyboardDoubleArrowRightIcon sx={{ ml: 0, mr: 0 }} />
                      }
                    ></Button>
                  </Tooltip>
                </Container>
              </Grid>
            )}
          </div>

          {loadingBottom && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            </Box>
          )}

          {nextPage != "" ? (
            <>
              <LoadingButton
                sx={{ mt: 2, mb: 8, width: "100%" }}
                onClick={loadMoreOnClick}
                loading={loadingBottom}
                loadingIndicator="Loading…"
                variant="outlined"
              >
                Load More
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                disabled
                sx={{ mt: 2, mb: 6, width: "100%" }}
                variant="outlined"
              >
                No more results
              </LoadingButton>
            </>
          )}

          {tooMany && (
            <Alert
              sx={{
                mt: 0,
                mb: 6,
                width: "100%",
                textAlign: "center",
                display: "block",
              }}
              icon={false}
              variant="filled"
              severity="error"
            >
              Too many filters combined, try a different combination
            </Alert>
          )}
        </>
      )}
    </div>
  );
}

export default TableS;
