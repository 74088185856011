import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";



import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { createTheme } from '@mui/material/styles'
import {createStyles, Theme} from "@mui/material/styles";
// import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
// import PhoneInput from 'react-phone-input-2'


import {Grid, Box, Button, Container, Typography} from "@mui/material";
import Logo from "../assets/logo.svg";

import "../App.css";

import UploadComp from "../components/upload"

// import fetch from 'node-fetch'


const theme = createTheme({
    typography: {
      fontFamily: 'Helvetica, sans-serif', // default Material-UI font
        h2: {
            fontSize: "22px",
            fontWeight: "bold",
        }
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: "#454a4f"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    borderColor: "#454a4f"
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    marginTop: "20px"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                fontWeight: 'bold',
              },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
              // Name of the slot
              root: {
               
              
                ' .MuiTableSortLabel-icon': {
                    color: 'white',
                    opacity: '0.2'
                },
                '&.Mui-active .MuiTableSortLabel-icon': {
                    color: 'white',
                    opacity: '1'
                },
              }
            },
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#26E6FF'
        },
        secondary: {
            main: '#FF7390'
        },
      
    },
  });



export interface PhotoSignatureProps {
    fullName: string;
    position: string;
    phone: string;
    websitegroup: string;
    innerhtml: string;
}

interface State extends PhotoSignatureProps {
    copied: boolean;
    copiedSF: boolean;
}

const initialState: State = {
    fullName: "",
    position: "",
    phone: "",
    websitegroup: "services",
    copied: false,
    copiedSF: false,
    innerhtml: "",
};



function Upload() {

    

    var userJson = localStorage.getItem("user");

    function testJSON(text: string | null) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    var loggedInUser = (testJSON(userJson)) ? JSON.parse(userJson || '') : null;


    // const photoUrlMaxLength = 1000;

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
                <Grid
                    
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 5, width: "100%" }}
                 
                >  
                <Grid item md={12} sx={{ width: "100%" }}>
                    <Link to={"/"}>
                    <img className="logo" src={Logo} alt={"logo"}/>
                    </Link>
                </Grid>
                    <Grid item md={12} sx={{ width: "100%" }}>
                        
                        
                        <Typography sx={{mb: 2}} variant="h2" align="center">
                            Upload
                        </Typography>
                      
                        <>
                            {loggedInUser && loggedInUser.email && loggedInUser.email.includes('@raconteur.net') ? (
                                <><UploadComp/></>
                            ) : loggedInUser && loggedInUser.email && !loggedInUser.email.includes('@raconteur.net') ? (
                                <>
                                    <h3>Not a raconteur account</h3>
                                </>
                            ) : (
                                <>
                                	
                                    <Navigate to="/" />
                                </>
                            )}
                        </>

                    </Grid>
                
                </Grid>
            </Container>
        </ThemeProvider>
       
    );
}

export default Upload;
