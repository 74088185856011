import { useEffect, useMemo, useState, useCallback } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { Alert, IconButton, Snackbar, TextField } from "@mui/material";

type AdsFilterProps = {
  adsFilterData: (data: string) => void;
  adsSearch: (data: string) => void;
};

const AdsFilter = React.memo(function AdsFilter({
  adsFilterData,
  adsSearch,
}: AdsFilterProps) {
  const today = useMemo(() => new Date(), []);

  const initialDates = useMemo(() => {
    const initialDate = new Date(today);
    initialDate.setDate(initialDate.getDate() - 2);
    const currentDate = dayjs(initialDate).format("YYYY-MM-DD");

    initialDate.setDate(initialDate.getDate() - 30);
    const date30Days = dayjs(initialDate).format("YYYY-MM-DD");

    return { currentDate, date30Days };
  }, [today]);

  const { currentDate, date30Days } = initialDates;

  const min2024 = useMemo(() => dayjs("01/03/2024"), []);

  const [currentDateSelect, setCurrentDateSelect] = useState(
    dayjs(currentDate)
  );

  const [date30DaysSelect, setDate30DaysSelect] = useState(dayjs(date30Days));
  const [error, setError] = useState("");

  // Use the state to keep track of both start and end dates
  const [startDate, setStartDate] = useState(dayjs(date30Days));
  const [endDate, setEndDate] = useState(dayjs(currentDate));

  // Combine start and end dates into a single state
  const [data, setData] = useState(`${date30Days}:${currentDate}`);

  const handleAdsSearch = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      const companyAdsValue = (
        document.getElementById("AdsSearch") as HTMLInputElement
      ).value;
      adsSearch(companyAdsValue);
    },
    [adsSearch]
  );

  useEffect(() => {
    adsFilterData(data);
    // console.log(data);
  }, [data, adsFilterData]);

  const handleStartDateChange = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        const newStartDate = newValue.format("YYYY-MM-DD");
        const newStartDateObj = dayjs(newStartDate);
        setDate30DaysSelect(newStartDateObj);
        setStartDate(newStartDateObj);
        if (endDate.diff(newStartDateObj, "day") > 89) {
          setError("START and END dates cannot be more than 90 days apart.");
          setData("dateRangeError");
        } else {
          setData(`${newStartDate}:${endDate.format("YYYY-MM-DD")}`);
        }
      }
    },
    [endDate]
  );

  const handleEndDateChange = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (newValue) {
        const newEndDate = newValue.format("YYYY-MM-DD");
        const newEndDateObj = dayjs(newEndDate);
        setCurrentDateSelect(newEndDateObj);
        setEndDate(newEndDateObj);
        if (newEndDateObj.diff(startDate, "day") > 89) {
          setError("START and END dates cannot be more than 90 days apart.");
          setData("dateRangeError");
        } else {
          setData(`${startDate.format("YYYY-MM-DD")}:${newEndDate}`);
        }
      }
    },
    [startDate]
  );

  return (
    <>
      <Box mt={6} mb={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Start date"
                value={date30DaysSelect}
                format="LL"
                sx={{ width: "100%" }}
                minDate={min2024}
                maxDate={endDate}
                disableFuture
                onChange={handleStartDateChange}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="End date"
                value={currentDateSelect}
                minDate={date30DaysSelect}
                maxDate={dayjs(currentDate)}
                sx={{ width: "100%" }}
                disableFuture
                onChange={handleEndDateChange}
                format="LL"
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box mb={4}>
        <form
          className="form"
          style={{ padding: 0 }}
          onSubmit={handleAdsSearch}
        >
          <TextField
            id="AdsSearch"
            label="Search by campaign name, page title or URL"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton
                  id="searchIcon"
                  onClick={handleAdsSearch}
                  aria-label="search"
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </form>
      </Box>
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          message={error}
          onClose={() => setError("")}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            variant="filled"
            sx={{ width: "100%", color: "white", fontWeight: "bold" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
});

export default AdsFilter;
