"use client";

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LinkIcon from "@mui/icons-material/Link";
import SellIcon from "@mui/icons-material/Sell";

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

// import { ImageAreaProps } from "@/types";
import { FileRejection } from "react-dropzone";
import Dropzone from "react-dropzone";
import { saveAs } from "file-saver";
import {
  Chip,
  Typography,
  Paper,
  Tooltip,
  Snackbar,
  Alert,
  Button,
  CircularProgress,
} from "@mui/material";
import { useTheme, styled, ThemeProvider } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

import ColorPicker from "./colorPicker";
import { set } from "lodash";

const racCdn =
  "https://res.cloudinary.com/yumyoshojin/image/fetch/w_500,h_500/l_fetch:";
const modX = "w_240,h_310,c_thumb,g_face";

type ImageAreaProps = {
  title: string;
  // icon: React.ForwardRefExoticComponent<
  //   Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
  //     title?: string | undefined;
  //     titleId?: string | undefined;
  //   } & React.RefAttributes<SVGSVGElement>
  // >;
};

type ErrorNotificationProps = {
  errorMessage: string;
};

type ActionPanelProps = {
  isLoading: boolean;
  submitImage(): void;
  buttonText: string;
};

type UploadedImageProps = {
  image: File;
  removeImage(): void;
  setCroppedImage: (base64Image: any) => void;
};

type ImageOutputProps = ImageAreaProps & {
  loading: boolean;
  outputImage: string | null;
  fileName: string;
  downloadOutputImage(): void;
};

interface RaconteurImageProps {
  outputImage: string;
  fileName: string;
}

const acceptedFileTypes = {
  "image/jpeg": [".jpeg", ".jpg", ".png"],
};

const maxFileSize = 50 * 1024 * 1024; // 50MB

const ImageOutput = React.memo((props: ImageOutputProps) => {
  // console.log(props);
  return (
    <section>
      <Box>
        {props.loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              items: "center",
              width: "100%",
              height: "300px",
            }}
          >
            <CircularProgress />
            <Typography display={"block"} sx={{ mt: 2 }} variant="overline">
              Processing the image
            </Typography>
          </Box>
        ) : null}

        {!props.outputImage && !props.loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              items: "center",
              width: "100%",
              height: "300px",
            }}
          >
            {/* <props.icon className="mx-auto h-12 w-12 text-gray-400" /> */}
            <Typography
              display={"block"}
              sx={{ mt: 2, p: 2, textAlign: "center", lineHeight: 1.8 }}
              variant="overline"
            >
              {props.title}
            </Typography>
          </Box>
        ) : null}

        {!props.loading && props.outputImage ? (
          <RaconteurImages
            outputImage={props.outputImage}
            fileName={props.fileName}
          />
        ) : // <img
        //   src={props.outputImage}
        //   alt={props.title}
        //   style={{ width: "100%" }}
        // />
        null}
      </Box>
    </section>
  );
});

const ImageDropzone = React.memo(
  (
    props: ImageAreaProps & {
      onImageDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]): void;
    }
  ) => {
    return (
      <Dropzone
        onDrop={props.onImageDrop}
        accept={acceptedFileTypes}
        maxSize={maxFileSize}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <input {...getInputProps()} />
            <button
              {...getRootProps()}
              type="button"
              style={{
                width: "100%",
                height: "600px",
                borderRadius: "10px",
                backgroundColor: "#eee",
                border: 0,
                appearance: "none",
              }}
              className=""
            >
              {/* <props.icon className="mx-auto h-12 w-12 text-gray-400" /> */}
              <div>
                <CloudUploadIcon sx={{ fontSize: "80px" }} />
                <Typography fontWeight={"bold"}>{props.title}</Typography>
              </div>
            </button>
          </>
        )}
      </Dropzone>
    );
  }
);

const UploadedImage = React.memo(
  ({ image, removeImage, setCroppedImage }: UploadedImageProps) => {
    // console.log(image);
    const cropperRef = useRef<ReactCropperElement>(null);

    const onCrop = useCallback(() => {
      const cropper = cropperRef.current?.cropper;
      // console.log(cropper);
      if (cropper) {
        // console.log(cropper.getCroppedCanvas().toDataURL());
        setCroppedImage(cropper.getCroppedCanvas().toDataURL());
      }
    }, []);

    // set img as memo of image
    const img = useMemo(() => {
      return (
        <Cropper
          src={URL.createObjectURL(image)}
          style={{ width: "100%", margin: 0, padding: 0 }}
          // Cropper.js options
          initialAspectRatio={40 / 49}
          guides={false}
          cropend={onCrop}
          ready={onCrop}
          ref={cropperRef}
          aspectRatio={40 / 49}
          zoomable={false}
          zoomOnTouch={false}
          zoomOnWheel={false}
          minCropBoxWidth={100}
          toggleDragModeOnDblclick={false}
          viewMode={1}
          center={false}
        />
      );
    }, [image]);

    return (
      <div style={{ position: "relative", lineHeight: 0 }}>
        {img}

        <Button
          variant="contained"
          onClick={removeImage}
          color="error"
          sx={{ position: "absolute", bottom: "10px", left: "10px" }}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  }
);

const ActionPanel = React.memo(
  ({ isLoading, submitImage, buttonText }: ActionPanelProps) => {
    const isDisabled = isLoading;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          mt: 6,
        }}
      >
        <Button
          type="button"
          disabled={isDisabled}
          onClick={submitImage}
          startIcon={<AutoFixHighIcon />}
          variant="contained"
          color="secondary"
          size="large"
        >
          {buttonText}
        </Button>
        <Typography sx={{ mt: 2, textAlign: "center" }} variant="caption">
          Do not use this tool on images that have{" "}
          <strong>already been processed</strong>; it will reduce their quality.
        </Typography>
      </Box>
    );
  }
);

const RaconteurImages: React.FC<RaconteurImageProps> = ({
  outputImage,
  fileName,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [color, setColor] = useState<string>("#26e6ff");

  const raconteurColors = [
    "#26e6ff",
    "#005ba5",
    "#7080ed",
    "#f34a4f",
    "#EFE7DB",
    "#FFCE5C",
    "#00BF9D",
    "#191919",
    // "#ffffff",
  ];

  function downloadCanvas() {
    // Access the canvas element
    const canvas = document.getElementById(
      "raconteur-canvas"
    ) as HTMLCanvasElement;

    if (!canvas) {
      // setError("No canvas element found.");
      return;
    }

    // Convert the canvas content to a data URL
    const dataURL = canvas.toDataURL("image/png");

    // Initiate the download using the data URL
    const link = document.createElement("a");
    link.href = dataURL;
    // encode the file name
    const fileNameEncoded = encodeURIComponent(fileName);
    link.download = "Raconteur-Headshot_" + fileNameEncoded;
    link.click();

    // Clean up
    URL.revokeObjectURL(dataURL);
  }

  useEffect(() => {
    // Check if outputImage is a base64 encoded string
    if (outputImage && outputImage.startsWith("data:image/")) {
      const img = new Image();
      img.src = outputImage;

      img.onload = function () {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Set canvas dimensions
        canvas.width = img.width;
        canvas.height = img.height - 0;

        // Define the radius for the bottom corners and circle
        const radius = canvas.width / 2;
        const circleRadius = radius; // Radius of the circle
        const circleX = canvas.width / 2; // Center x of the circle
        const circleY = canvas.height - circleRadius; // Center y of the circle

        // Draw the colored circle at the bottom
        ctx.beginPath();
        ctx.arc(circleX, circleY, circleRadius, 0, Math.PI * 2);
        ctx.fillStyle = color;
        ctx.fill();

        // Create a path with rounded bottom corners that matches the circle
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, canvas.height - radius);
        ctx.arcTo(0, canvas.height, radius, canvas.height, radius);
        ctx.lineTo(canvas.width - radius, canvas.height);
        ctx.arcTo(
          canvas.width,
          canvas.height,
          canvas.width,
          canvas.height - radius,
          radius
        );
        ctx.lineTo(canvas.width, 0);
        ctx.closePath();

        // Clip to the rounded path
        ctx.clip();

        // Draw the image onto the canvas

        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height - 0,
          0,
          0,
          canvas.width,
          canvas.height
        );

        // Restore the full clipping region
        ctx.restore();
      };
    }
  }, [outputImage, color]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        lineHeight: 0,
      }}
    >
      <div
        style={{ position: "absolute", top: "-20px", left: "20px", zIndex: 99 }}
      >
        <ColorPicker
          raconteurColors={raconteurColors}
          color={color}
          onChange={setColor}
        />
      </div>
      <div
        style={{
          position: "relative",
          lineHeight: 0,
          zIndex: 90,
          width: "300px",
          // marginTop: "20px",
        }}
      >
        <canvas
          ref={canvasRef}
          id="raconteur-canvas"
          style={{
            display: "block",
            position: "relative",
            width: "100%",
            zIndex: 3,
          }}
        ></canvas>
      </div>

      <Button
        sx={{
          mt: 4,
          borderRadius: "0px",
          borderBottomLeftRadius: "25px",
          borderBottomRightRadius: "25px",
        }}
        onClick={downloadCanvas}
        fullWidth
        variant="contained"
      >
        Download
      </Button>
    </Box>
  );
};

interface ProgressItem {
  file: string;
  progress?: number;
  status?: string;
  [key: string]: any;
}

interface WorkerMessage {
  status: string;
  file?: string;
  progress?: number;
  output?: string;
  [key: string]: any;
}

function ImageArea() {
  const theme = useTheme();
  const [message, setMessage] = useState("");
  const [outputImage, setOutputImage] = useState<string | null>(null);
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>("");
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [buttonText, setButtonText] = useState<string>(
    "Make it Raconteur ready"
  );

  function onImageDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    // Check if any of the uploaded files are not valid
    if (rejectedFiles.length > 0) {
      // console.info(rejectedFiles);
      setError("Please upload a PNG or JPEG image less than 5MB.");
      return;
    }

    removeImage();

    setError("");
    setFile(acceptedFiles[0]);
    // get the file name from file
    const fileName = acceptedFiles[0].name;
    // change the filename to png
    const fileNameWithoutExtension = fileName.split(".")[0];
    const finalFileName = fileNameWithoutExtension + ".png";
    setFileName(finalFileName);

    // Convert to base64
    convertImageToBase64(acceptedFiles[0]);
  }

  function convertImageToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const binaryStr = reader.result as string;
      setBase64Image(binaryStr);
    };
  }

  function removeImage() {
    setFile(null);
    setOutputImage(null);
  }

  function downloadOutputImage() {
    saveAs(outputImage as string, "output.png");
  }

  async function submitImage() {
    if (!file) {
      setError("Please upload an image.");
      return;
    }

    // const currentCount = apiCount + 1;
    // apiCount++;

    setLoading(true);

    // console.log(file.name);
    async function removeBackground() {
      if (buttonText == "Headshot found - Click again to re-process") {
        return true;
      } else {
        return false;
      }
    }
    // console.log(removeBackground());
    setButtonText("Removing background");

    const fileExistsInCloudinary = await fetch(
      "/.netlify/functions/headshotBackgroundCloudinaryExists",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: file.name,
          image: base64Image,
          croppedImage: croppedImage,
          forceRemoveBackground: await removeBackground(),
        }),
      }
    )
      .then((res) => res.json())
      .catch((error) => "timeout");

    // console.log("fileExistsInCloudinary", fileExistsInCloudinary);

    if (fileExistsInCloudinary.removeBackground) {
      // console.log(fileExistsInCloudinary.imageUrl);
      let finalResponse = "";

      // console.log("finalResponse 1", fileExistsInCloudinary);
      // const response = await removeBackground(
      //   fileExistsInCloudinary.inputImageCloudinaryUrl
      // );
      // finalResponse = response.base64ImageResult;
      const finalFileNameUploadedWithoutExtension = file.name.split(".")[0];
      const finalFileNameUploaded =
        "00_" + finalFileNameUploadedWithoutExtension;

      setButtonText("Stiching with the background circle");
      const response = await removeBackground2(
        fileExistsInCloudinary.inputImageCloudinaryUrl,
        file.name,
        finalFileNameUploaded
      );
      finalResponse = response.base64ImageResult;

      // wait for 8 seconds
      await new Promise((resolve) => setTimeout(resolve, 8000));

      // try to fetch /.netlify/functions/headshotBackgroundRemBgCheck and if the response is 202 then wait 8 seconds and try again
      let responseCheck = await fetch(
        "/.netlify/functions/headshotBackgroundRemBgCheck",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            finalFileNameUploaded: finalFileNameUploaded,
          }),
        }
      )
        .then((res) => res.json())
        .catch((error) => error);

      // console.log("responseCheck", responseCheck);

      if (responseCheck.status == 202) {
        // wait for 8 seconds
        setButtonText("Generating the final image");
        console.log("cheking again");
        await new Promise((resolve) => setTimeout(resolve, 8000));
        console.log("cheking again - checked");
        responseCheck = await fetch(
          "/.netlify/functions/headshotBackgroundRemBgCheck",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              finalFileNameUploaded: finalFileNameUploaded,
            }),
          }
        )
          .then((res) => res.json())
          .catch((error) => error);
      }

      if (responseCheck.base64ImageResult) {
        setButtonText("Make it Raconteur ready");
        setOutputImage(responseCheck.base64ImageResult);
      }

      // console.log(apiCount);
    } else {
      setButtonText("Headshot found - Click again to re-process");
      setOutputImage(fileExistsInCloudinary.base64ImageResult);
    }

    setLoading(false);
  }

  async function removeBackground2(
    inputImageCloudinaryUrl: any,
    inputImagePath: any,
    finalFileNameUploaded: any
  ) {
    try {
      const response = await fetch(
        "/.netlify/functions/headshotBackgroundRemBg-background",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inputImageCloudinaryUrl: inputImageCloudinaryUrl,
            inputImagePath: inputImagePath,
            finalFileNameUploaded: finalFileNameUploaded,
          }),
        }
      )
        .then((res) => res.json())
        .catch((error) => "timeout");

      return response;
    } catch (error) {
      return "error";
    }
  }

  return (
    <>
      <ActionPanel
        isLoading={loading}
        submitImage={submitImage}
        buttonText={buttonText}
      />

      <Box
        mt={6}
        sx={{
          opacity: loading ? "0.5" : "1",
          pointerEvents: loading ? "none" : "all",
          transition: "all .2s ease-in-out",
          display: "flex",
          alignContent: "flex-start",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            width: "calc(50% - 80px)",
            marginRight: "80px",
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: "100%",
              borderRadius: "25px",
              padding: "30px",
              paddingTop: "10px",
            }}
          >
            <Chip
              label={<Typography fontWeight={"bold"}>Upload photo</Typography>}
              color="primary"
              sx={{
                marginTop: "-50px",
                marginLeft: "20px",
              }}
            />
            <Box>
              <Box>
                {!file ? (
                  <ImageDropzone
                    title={`Drag and drop your image here or click to upload`}
                    onImageDrop={onImageDrop}
                    // icon={PhotoIcon}
                  />
                ) : (
                  <UploadedImage
                    image={file}
                    removeImage={removeImage}
                    setCroppedImage={setCroppedImage}
                  />
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            width: "50%",
            pointerEvents: loading ? "none" : "all",
          }}
        >
          <Paper
            sx={{
              width: "100%",
              borderRadius: "25px",
              padding: "0px",
              paddingTop: "0px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Chip
                label={
                  <Typography
                    fontWeight={"bold"}
                    color={"black"}
                    display={"block"}
                    textAlign={"center"}
                  >
                    Generated headshot
                  </Typography>
                }
                color="primary"
                sx={{
                  // marginTop: "-15px",
                  transform: "translateY(-50%)",
                  marginRight: "40px",
                  // marginLeft: "100%",
                  // transform: "translateX(calc(100%))",
                }}
              />
            </div>

            <ImageOutput
              title={`Upload a new image on the left to generate raconteur headshots`}
              downloadOutputImage={downloadOutputImage}
              outputImage={outputImage}
              // icon={SparklesIcon}
              loading={loading}
              fileName={fileName}
            />
          </Paper>
        </Box>
      </Box>
      {message != "" && (
        <Snackbar
          open={true}
          // autoHideDuration={4000}
          message={message}
          // onClose={() => setMessage("")}
        >
          <Alert
            // onClose={() => setMessage("")}
            icon={false}
            severity="info"
            variant="filled"
            sx={{
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {error != "" && (
        <Snackbar
          open={true}
          autoHideDuration={4000}
          message={error}
          onClose={() => setError("")}
        >
          <Alert
            onClose={() => setError("")}
            icon={false}
            severity="error"
            variant="filled"
            sx={{
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ImageArea;
